import { Col, Button, Row, Container, Card, Form } from "react-bootstrap";
import React, {useState, useContext} from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios"
import { Context } from "../../component/hooks/Wrapper";
import { FormattedMessage } from "react-intl";
import "./LoginPage.css"

export default function LoginPage() {

  const context = useContext(Context)
	const navigate = useNavigate();
	const initialFormData = Object.freeze({
                                        password: '',
                                        email: '',
                                      })
	const [formData, updateFormData] = useState(initialFormData);
	const [loginEorror, setLoginError] = useState(false)

  //handle changes on the input form
  const handleChange = (e) => {
		updateFormData({
			...formData,
			[e.target.name]: e.target.value.trim(),
		});
	};

  //submit handler of the form
  const handleSubmit = async (e) => {
    e.preventDefault();
    let response;
    let webUserData
  
    try {
      console.log("before login", {
        email: formData.email,
        password: formData.password,
      })
      response = await axios.post(`${context.baseURL}auth/token/login/`, {
        email: formData.email,
        password: formData.password,
      });
      //context.setToken(response.data.auth_token);
      console.log("login", response)
      const headers = {Authorization: `token ${response.data.auth_token}`,}
      context.setToken(headers)
      webUserData = await  axios.get(`${context.baseURL}user/UserInfo/`, { headers: headers })

      context.setUserData({ email:webUserData.data.email,
                            firstName:webUserData.data.first_name,
                            lastName:webUserData.data.last_name,
                            userName:webUserData.data.username,
                            isStaff:webUserData.data.is_staff,
                            isActivate:webUserData.data.is_active,
                          })
      
      context.setLoginStatus(true)
      //set there are no login error
      setLoginError(false)
      //empty the email field and password field
      updateFormData(initialFormData)
      //navigate to the index page
      navigate('/UserInfo')

    } catch (error) {
      setLoginError(true)
      // console.error(error);
    }
  };

  return (
    <div>
      <Container>
        <Row className="vh-100 d-flex justify-content-center align-items-center">
          <Col md={8} lg={6} xs={12}>
            <div className="border border-3 border-warning"></div>
            <Card className="shadow">
              <Card.Body>
                <div className="mb-3 mt-md-4">
                  <h2 className="fw-bold mb-2 text-uppercase ">
                    <FormattedMessage id="app.logo" defaultMessage="Hong Kong Liverpool Link"/>
                  </h2>
                  <p className=" mb-5">
                    <FormattedMessage id="app.loginMessage" defaultMessage="Please enter your login and password!" />
                    </p>
                  <div className="mb-3">
                    <Form >
                      {/* for enter email address */}
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label className="text-center">
                          <FormattedMessage id="app.email" defaultMessage="Email address" />
                        </Form.Label>
                        <Form.Control type="email" className={loginEorror?"loginBorder":""} placeholder="Enter email" name="email" onChange={handleChange}/>
                      </Form.Group>

                      {/* for entering password */}
                      <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>
                          <FormattedMessage id="app.password" defaultMessage="Password" />
                        </Form.Label>
                        <Form.Control className={loginEorror?"loginBorder":""} type="password" placeholder="Password" name="password" onChange={handleChange}/>
                      </Form.Group>
                      
                      {/* for forget password */}
                      <Form.Group className="mb-3" controlId="formBasicCheckbox">
                        <div className="small" onClick={()=>navigate("/ForgetPassword")}>
                          <p className="text-primary underline hover:cursor-pointer">
                            <FormattedMessage id="app.forgetPW" defaultMessage="Forgot password" />?
                          </p>
                        </div>
                      </Form.Group>

                      <div className="d-grid">
                        <Button variant="warning" type="submit" onClick={handleSubmit}>
                          <FormattedMessage id="app.login" defaultMessage="Login" />
                        </Button>
                      </div>
                    </Form>
                    <div className="mt-3">
                      <p className="mb-0  text-center">
                        <FormattedMessage id="app.noAccount" defaultMessage="Don't have an account"/>?{" "}
                        <Button variant="warning" onClick={()=>navigate("/Register")} >
                          <FormattedMessage id="app.signUp" defaultMessage="Sign Up" />
                        </Button>
                      </p>
                      
                      {loginEorror? 
                      <p className="errorText">
                        Wrong Email or password
                      </p>
                      :""}
                      
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}