import React, {useContext, useState, useEffect} from 'react'
import { Context } from '../../../../component/hooks/Wrapper'
import Events2Table2 from './Events2Table2'
import Events2Table1 from './Events2Table1'
import AboutUsTemplate from '../../../../pages/AboutUS/AboutUsTemplate'
import tab2pic1 from '../../../pic/act2tab1.jpg'
import tab2pic2 from '../../../pic/act2tab2.jpg'
import Container from 'react-bootstrap/Container';
import useWIndowSize from '../../../../component/hooks/useWIndowSize'
import Row from 'react-bootstrap/Row';

const Event2description = () => {

    const context = useContext(Context)
    const {screenDim} = useWIndowSize()
    const [lang, setLang] = useState("")
    const [showImage, setShowImage] = useState(true)

    useEffect(()=>{
      String(context.locale).includes("en")? setLang("EN"):setLang("ZH")  
    },[context.locale])

    useEffect(()=>{
        if (screenDim.width >= 900){
          setShowImage(true)
        } else if (screenDim.width < 900){
          setShowImage(false)
        }}
        ,[screenDim.width]
      )

    return(
        <>
            <div>
                <h1 className="text-center">
                    {lang==="EN"? "Liverpool Hong Kong Link C.I.C. (LHKL)":"利港連線"}
                </h1>
            
                <h3 className="text-center">
                    {lang==="EN"? 
                    "LHKL Inauguration Event cum Career/Pension/Entrepreneurship talks"
                    :
                    "利港連線就職典禮暨職業/退休/創業綜合講座"}
                </h3>
            </div>

            <Container fluid>
                <AboutUsTemplate show={showImage} pic={tab2pic1} content={<Events2Table1/>}/>
                <Row>
                    <h3 className="text-center">
                        {lang==="EN"? "Rundown of the ceremony":"開幕典禮流程"}
                    </h3>
                </Row>
                <AboutUsTemplate show={showImage} pic={tab2pic2} content={<Events2Table2/>}/>
            </Container>

        </>
    )
}

export default Event2description
