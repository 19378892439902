import React from 'react';
import CopyrightIcon from '@mui/icons-material/Copyright';
import './Footer.css'
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import EmailIcon from '@mui/icons-material/Email';
import InstagramIcon from '@mui/icons-material/Instagram';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import EmailFooter from './component/EmailFooter';

const Footer = () => {
  return (
    <>
      <footer className="footer">
        <Container Fluid>
            <Row>
                <Col className=''>
                  <Col>
                    Contact us by E-mail
                  </Col>

                  <EmailFooter title="For General Enquiry" email="info@lhkl.org.uk" />
                  <EmailFooter title="For Short File Festival" email="film@lhkl.org.uk" />
                  <EmailFooter title="For Job consultation" email="job@lhkl.org.uk" />                 
                </Col>


                <Col>
                  <Col>
                    Our Social Media
                  </Col>

                  <Col>
                    <YouTubeIcon 
                      fontSize="large" 
                      className="footerIcon hover:cursor-pointer hover:cursor-pointer hover:bg-yellow-400 hover:rounded-lg"
                      onClick={(e) => {
                        e.preventDefault();
                        window.open("https://www.youtube.com/channel/UCQAiKf0tjW8C9eqsZ7iVVKw", "_blank")
                      }}
                    />
                    Youtube
                  </Col>

                  <Col>
                    <FacebookIcon 
                      fontSize="large" 
                      className="footerIcon hover:cursor-pointer hover:cursor-pointer hover:bg-yellow-400 hover:rounded-lg"
                      onClick={(e) => {
                        e.preventDefault();
                        window.open("https://www.facebook.com/groups/424895409566288", "_blank")
                      }}
                    />
                    Facebook
                  </Col>


                  <Col>
                    <InstagramIcon 
                      fontSize="large" 
                      className="footerIcon hover:cursor-pointer hover:cursor-pointer hover:bg-yellow-400 hover:rounded-lg"
                      onClick={(e) => {
                        e.preventDefault();
                        window.open("https://www.facebook.com/groups/424895409566288", "_blank")
                      }}
                    />
                    Instagram
                  </Col>

              </Col>

            </Row>
        <p className='footerText m-0'>
            <CopyrightIcon fontSize="large" className='pr-2'/>
            2023 Liverpool HongKong Link C.I.C., registered in England and Wales (14488239)
        </p>
        </Container>
      </footer>
    </>
  );
};

export default Footer;