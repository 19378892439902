import React, { useState, useEffect } from 'react'
import Form from 'react-bootstrap/Form';

const TableInputTemplate = (props) => {

    const [addtionProps, setAdditionProps] = useState({})

    useEffect(() => {
        // Determine additional props based on the formPurpose
        if (props.formPurpose === "submitVideo") {
          //setAdditionProps({ placeholder: props.placeholder, value: props.value });
          setAdditionProps({ placeholder: props.placeholder, value: props.value });
        } else if (props.formPurpose === "editVideo") {
          setAdditionProps({ value: props.formik.values[props.name] });
        }
      }, [props.formPurpose, props.formik]); 

    const Content = ()=>{

        if (props.type === "text") {
            return(
                <>
                    <Form.Control
                        size="lg"
                        type="text"
                        name={props.name}
                        disabled={props.disabled}
                        onChange={props.formik.handleChange}
                        {...addtionProps}
                        className={`border-2 border-black rounded-lg ${props.formik.touched[props.controlID] && props.formik.errors[props.controlID] ? 'RegisterFormError' : ''}`}             
                        onBlur={props.formik.handleBlur} 
                    />
                    {props.formik.errors[props.name] ? <div className="text-danger">{props.formik.errors[props.name]}</div> : null}
                </>
            )
        }
        else if (props.type === "textarea") {
            return(
                <>
                    <Form.Control
                        {...addtionProps}
                        size="lg"
                        as="textarea"
                        rows={props.row}
                        name={props.name}
                        disabled={props.disabled}
                        onChange={props.formik.handleChange}
                        {...addtionProps}
                        className={`border-2 border-black rounded-lg ${props.formik.touched[props.controlID] && props.formik.errors[props.controlID] ? 'RegisterFormError' : ''}`}             
                        onBlur={props.formik.handleBlur} 
                    />
                    {props.formik.touched[props.name] && props.formik.errors[props.name] ? <div className="text-danger">{props.formik.errors[props.name]}</div> : null}
                </>
            )
        }
        else if (props.type === "selection"){
            return(
                <>
                <Form.Select 
                    size="lg" 
                    //className="border-2 border-black rounded-lg"
                    name={props.name}
                    disabled={props.disabled}
                    onChange={props.formik.handleChange}
                    value={props.formik.values[props.name]} 
                    className={`border-2 border-black rounded-lg ${props.formik.touched[props.name] && props.formik.errors[props.name] ? 'RegisterFormError' : ''}`}
                    onBlur={props.formik.handleBlur} 
                >
                        
                    <option>Select</option>
                    {props.selectionList.map((category, key)=>{
                        return(
                            <option key={key} value={category}>{category}</option>
                        )
                    })}
                </Form.Select>
                
                {props.formik.touched[props.name] && props.formik.errors[props.name] ? <div className="text-danger">{props.formik.errors[props.name]}</div> : null}
                </>
            )
        }
        else if (props.type === "number"){
            return(
                <>
                    <Form.Control 
                        className={`border-2 border-black rounded-lg ${props.formik.touched[props.controlID] && props.formik.errors[props.controlID] ? 'RegisterFormError' : ''}`}
                        //placeholder= {props.formik.values[props.name]}
                        {...addtionProps}
                        size="lg" 
                        type="text" 
                        name={props.name} 
                        pattern="[0-9]*"
                        onKeyDown={(event) => {
                            if (!/[0-9]/.test(event.key) && event.key !== 'Backspace' && event.key !== 'Delete') {
                                event.preventDefault();
                            }
                        }}
                        onChange={props.formik.handleChange}
                        disabled={props.disabled}
                    />
                    {props.formik.touched[props.name] && props.formik.errors[props.name] ? <div className="text-danger">{props.formik.errors[props.name]}</div> : null}
                </>
            )
        }
        else if (props.type === "date"){
            return(
                <>
                    <Form.Control
                        {...addtionProps}
                        size="lg"
                        type="date"
                        name={props.name}
                        onChange={props.formik.handleChange}
                        disabled={props.disabled}
                        className={`border-2 border-black rounded-lg ${props.formik.touched[props.controlID] && props.formik.errors[props.controlID] ? 'RegisterFormError' : ''}`}
                        onBlur={props.formik.handleBlur} 
                    />

                    {props.formik.touched[props.name] && props.formik.errors[props.name] ? <div className="text-danger">{props.formik.errors[props.name]}</div> : null}
                </>
            )

        }
    }

    return (
        <>
            <Form.Group  className='p-0 px-1' controlId={props.controlID}>
                <Form.Label className='m-0 pt-1 whitespace-nowrap'>{props.label}</Form.Label>
                {Content()}
            </Form.Group>
        </>
    )
}

export default TableInputTemplate
