import React from 'react'
import EmailIcon from '@mui/icons-material/Email';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import "./EmailFooter.css"

const EmailFooter = (props) => {
    return (
        <>
            <Col>
                
                {props.title}{' '}

                <EmailIcon
                    fontSize="large"
                    className="footerIcon"
                    onClick={() => window.open(`mailto:${props.email}`)}
                />

                {' '}{props.email}
            </Col>
        </>
    )
}

export default EmailFooter
