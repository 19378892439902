import Nav from 'react-bootstrap/Nav';
import { Link } from 'react-router-dom';

function UserinfoNavbar() {
  return (
    <Nav defaultActiveKey="/UserInfo" as="ul">
      <Nav.Item as="li">
        <Nav.Link as={Link} to="/UserInfo">Submit and Edit Video</Nav.Link>
      </Nav.Item>
      <Nav.Item as="li">
        <Nav.Link as={Link} to="/UserInfo/HowToSubmit">How to submit video</Nav.Link>
      </Nav.Item>
    </Nav>
  );
}

export default UserinfoNavbar;