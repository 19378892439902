import React, {useContext, useState, useEffect} from 'react'
import { Context } from '../../../../component/hooks/Wrapper'
import Table from 'react-bootstrap/Table';
import "./Event2description.css"

const Events2Table1 = () => {

    const context = useContext(Context)

    const [lang, setLang] = useState("")

    useEffect(()=>{
      String(context.locale).includes("en")? setLang("EN"):setLang("ZH")  
    },[context.locale])

    const WorkPensionWeb = "https://www.gov.uk/government/organisations/department-for-work-pensions"
    const MCTwebsite = "https://mymct.co.uk/"
    const ManCityWeb = "https://www.manchester.gov.uk/"
    const LiverpoolMapWeb = "https://www.google.com/maps/place/Central+Library/@53.4098424,-2.9828633,17z/data=!3m2!4b1!5s0x487b213b09b16b03:0xc6f63d723814f88c!4m6!3m5!1s0x487b213b05a3aa69:0x5f4ca882f45b6b5d!8m2!3d53.4098392!4d-2.9802884!16zL20vMGZfZHQy?entry=ttu"
    const googleFormLink = "https://docs.google.com/forms/d/e/1FAIpQLScGMmVMM2gDwbbrZ8tyW1vfmSbtDoRu0g6HuCSBlRCukfxBfg/viewform"

    return (
        <>
            <Table striped bordered hover variant="light">
                <tbody>
                    {/* row 1 */}
                    <tr >
                        <td>{lang==="EN"?"Supporting Organizations:":"支持機構:"}</td>
                        <td>
                            {lang==="EN"?
                            <div>
                                <p><a href={ManCityWeb}>Manchester City Council,</a></p>
                                <p><a href={WorkPensionWeb}>Department for Work&Pensions,</a></p>
                                <p><a href={MCTwebsite}>Merseyside Community Training (MCT)</a></p>
                            </div>
                            :
                            <div>
                                <p><a href={ManCityWeb}>曼徹斯特市議會</a></p>
                                <p><a href={WorkPensionWeb}>就業及養老金部門</a></p>
                                <p><a href={MCTwebsite}>默西塞德郡社區培訓 (MyMCT)</a></p>    
                            </div>}
                        </td>
                    </tr>

                    {/* row 2 */}
                    <tr>
                        <td>{lang==="EN"?"Funding:":"資助:"}</td>
                        <td>
                            {lang==="EN"?
                            "National and Regional VCSE Grants from The North West Hong Kong BN(O) Welcome Hub and the Regional Strategic Migration Partnership (RSMP)"
                            :
                            "來自英格蘭西北部香港英國國民(海外)歡迎中心和區域策略移民合作伙伴關係(RSMP)的全國和地區性自願、社區、信仰和社會企業(VCFSE)資助金"}
                        </td>
                    </tr>   

                    {/* row 3 */}
                    <tr>
                        <td>{lang==="EN"?"Aim:":"目的:"}</td>
                        <td>
                            {lang==="EN"?
                            "To present LHKL community services, provide a comprehensive career workshop and launch the Liverpool Short Film Festival to HongKongers"
                            :
                            "為香港人介紹利港連線提供的社區服務、提供全面的職業工作坊及推行利物浦短片電影節"}
                        </td>
                    </tr> 

                    {/* row 4 */}
                    <tr>
                        <td>{lang==="EN"?"Time:":"時間:"}</td>
                        <td>
                            {lang==="EN"?
                            "18 August 2023 (Friday) 09:30am-12:30pm"
                            :
                            <div>
                                <p>2023年8月18日 (星期五)</p>
                                <p>上午09時30分-下午12時30分</p>
                            </div>}
                        </td>
                    </tr> 

                    {/* row 5 */}
                    <tr>
                        <td>{lang==="EN"?"Venue:":"地點:"}</td>
                        <td>
                            {lang==="EN"?
                            <div>
                                <a href={LiverpoolMapWeb}>
                                    <p>Meeting Room 1&2,</p> 
                                    <p>Liverpool Central Library</p>
                                    <p>William Brown St,</p>
                                    <p>Liverpool</p> 
                                    <p>L3 8EW</p>
                                </a>
                            </div>
                            :
                            <div>
                                <a href={LiverpoolMapWeb}>
                                    <p>會議室1和2,</p> 
                                    <p>利物浦中央圖書館</p>
                                    <p>威廉布朗街,</p>
                                    <p>利物浦</p>
                                    <p>L3 8EW</p>
                                </a>
                            </div>}
                        </td>
                    </tr> 

                    {/* row 6 */}
                    <tr>
                        <td>{lang==="EN"?"Target attendees:":"目標參加者:"}</td>
                        <td>{lang==="EN"?"5+1 BNO HongKongers":"持有英國國民(海外)簽證的香港人"}</td>
                    </tr> 

                    {/* row 7 */}
                    <tr>
                        <td>{lang==="EN"?"Live:":"直播:"}</td>
                        <td>
                            {lang==="EN"?
                            "Live streaming via Liverpool HongKong Link Facebook/ Youtube"
                            :
                            "通過利港連線Facebook/ Youtube 進行現場直播"}
                        </td>
                    </tr>                  

                    {/* row 8 */}
                    <tr>
                        <td>{lang==="EN"?"Interpretation:":"翻譯:"}</td>
                        <td>{lang==="EN"?"LHKL volunteer":"LHKL 義工"}</td>
                    </tr> 

                    {/* row 9 */}
                    <tr>
                        <td>{lang==="EN"?"Registration:":"報名:"}</td>
                        <td>{lang==="EN"?
                            <div>
                                <p><a href={googleFormLink}>Google Form</a> or Walk in</p>
                            </div>
                            :
                            <div>
                                <p>免費入場或<a href={googleFormLink}>Google Form</a>預先登記</p>
                            </div>}
                        </td>
                    </tr> 

                </tbody>
            </Table>
        </>
    )
}

export default Events2Table1
