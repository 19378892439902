import React, {useContext, useState, useEffect} from 'react'
import { Context } from '../../../../component/hooks/Wrapper'

const Event3Content = () => {

    const context = useContext(Context)
    const [lang, setLang] = useState("")
    const lineStyleZH = ""
    const lineStyleEN = ""

    useEffect(()=>{
        String(context.locale).includes("en")? setLang("EN"):setLang("ZH")
    },[context.locale])

    return (
        <>
            {lang === "EN"?
            <h1>Past Event:Friendship Festival</h1>
            :
            <h1>過去活動：友誼節</h1>}

            <h1>Feb 4, 2023</h1>

            {lang === "EN"? 
            <div>
                <p className={lineStyleZH}>Acknowledgment</p>
                <p className={lineStyleZH}>Distinguished guests, friends, and everyone, hello:</p>
                <p className={lineStyleZH}>Welcome to this Friendship Festival event jointly organized by Welcome Churches and our Liverpool Hongkong Link (LHKL), with support from various charitable organizations. We're grateful for your presence and the advisory services provided. This is an opportunity for Hong Kong people to come together, exchange ideas, make new friends, understand each other better, and help newly settled Hong Kong families integrate into the community.</p>
                <p className={lineStyleZH}>Since the UK introduced the LOTR and subsequently the BNO visas, allowing Hong Kong residents to apply for residency after 5 to 6 years, just over two years have passed. According to statistics, more than 120,000 people have migrated to the UK, with an estimated thousand independent families settling in the Liverpool area. In light of this, we established the "Liverpool Hongkong Link" to assist newly settled Hong Kong families in the region.</p>
                <p className={lineStyleZH}>Even more inspiring, the UK Home Office posted on social media, stating that the plan has already helped 144,500 Hong Kong people start new lives in the UK, praising their contributions to the UK! Secretary of State for the Home Department Robert Jenrick also posted a video on Twitter, mentioning that Hong Kong immigrants have made incredible contributions to the UK's economy and communities. He feels honored to have so many talented individuals making the UK their new home. The video posted on Twitter highlights the contributions of Hong Kong immigrants to the UK community and economy, including working for the National Health Service (NHS), assisting Afghan and Ukrainian refugees, and working in private institutions, with hopes for more qualified Hong Kong individuals to move to the UK in the future.</p>
                <p className={lineStyleZH}>Newly settled Hong Kong families have good educational backgrounds, knowledge, and work experiences spanning various industries, making it easier for them to engage in community service.</p>
                <p className={lineStyleZH}>We are pleased to receive support and collaboration from various Hong Kong people's organizations in the area, and we will diversify and develop different projects to serve Hong Kong families in the region. As the name implies, "Liverpool Hongkong Link" aims to connect the Hong Kong community in Liverpool.</p>
                <p className={lineStyleZH}>Furthermore, we want to express our gratitude to various organizations and volunteers from different fields. They provided us with valuable insights and assistance during the preparation process, enabling us to successfully organize this Friendship Festival event.</p>
                <p className={lineStyleZH}>Finally, we greatly appreciate your presence at today's event (with over 600 guests throughout the day). We also take this opportunity to wish you all a happy Lantern Festival and good health. Thank you all.</p>
                <p className={lineStyleZH}>Salute to the members of LHKL!</p>
            </div>
            :
            <div>
                <p className={lineStyleZH}>致 謝 辭</p>
                <p className={lineStyleZH}>各位來賓、各位朋友、大家好：</p>
                <p className={lineStyleZH}>歡迎大家出席是次由 Welcome Churches 支持，委托轄下組織UKHK與我們 Liverpool Hongkong Link (LHKL) 一起合辦今天舉行的友誼節活動。也感謝不少友好慈善機構臨支持及提供咨詢服務，這是一個可以讓香港人出席，匯集交流，藉此機會可以認識更多朋友，相互了解，讓定居不久的香港家庭可以盡快融入社區。</p>
                <p className={lineStyleZH}>自英國提出了LOTR及隨後的BNO簽證讓香港人可於5至6年後能申請獲取居留，至今剛滿兩年，據統計處指出，已有越12萬人數移居到英國，而在利物浦區內估計也有近千個獨立家庭遷入。有見及此，我們成立了「利港連線」，旨在幫助區內的香港新移居家庭。</p>
                <p className={lineStyleZH}>更鼓舞的是，英國內政部在社交媒體發文，指計劃兩年間已助144,500名港人在英國展開新生活，讚賞港人對英國貢獻!  入境國務大臣Robert Jenrick也在Twitter發佈影片，提到移民英國的香港人，對英國的經濟和社區作出非凡貢獻（incredible contributions），很榮幸能有這麼多人才以英國為新家。而在Twitter發布的影片中香港移民被指貢獻英國社區和經濟，包括為國民保健署（NHS）工作、幫助阿富汗及烏克蘭難民和在私營機構工作等，希望未來有更多合資格的港人移英。</p>
                <p className={lineStyleZH}>新移居的香港家庭，知識教育水平都很好，工作經驗上亦不差，而且來自廣泛的不同跨行業，比較容易投入社區服務。</p>
                <p className={lineStyleZH}>令我們感到欣慰的是，我們獲取區內很多不同的港人組織的支援和合作，日後會更多元化地發展不同項目為區內香港家庭服務。顧名思義，「利港連線」就是希望將利物浦的香港人連成一線。</p>
                <p className={lineStyleZH}>此外，我們要感謝不同範疇的組織和義工們，他們在籌備過程中，給予我們很多寶貴的意見和助力，讓我們得以順利舉辦友誼節活動。</p>
                <p className={lineStyleZH}>最後，我們非常感謝各位能抽空出席今天的活動(全日共有6百多位來賓)。也趁此機會預祝各位元宵節快樂，身心健康。謝謝各位。</p>      
                <p className={lineStyleZH}>LHKL仝人致敬!</p>
            </div>
            }

            <p>
                {lang === "EN"? 
                <p className={lineStyleEN}>Acknowledgements</p>
                :
                <p className={lineStyleZH}>特別鳴謝</p>}
            </p>

            <div>
                <p className={lineStyleEN}>Organising Committee:</p>
                <p className={lineStyleEN}>Committee Advisors:</p>
                <p className={lineStyleEN}>Welcome Churches</p>
                <p className={lineStyleEN}>UKHK</p>
                <p className={lineStyleEN}>Liverpool Hongkong Link</p>
                <br/>
                <p className={lineStyleEN}>Programme Committee:</p>
                <p className={lineStyleEN}>UKHK, LHKL</p>
                <br/>
                <p className={lineStyleEN}>Community Services:</p>
                <p className={lineStyleEN}>Welcome Churches</p>
                <p className={lineStyleEN}>Regional Strategic Migration Partnership</p>
                <p className={lineStyleEN}>Citizens Advise Liverpool</p> 
                <p className={lineStyleEN}>4Wings Liverpool -</p>
                <p className={lineStyleEN}>Hackney Chinese Community Services</p>
                <p className={lineStyleEN}>Barnado’s</p>
                <p className={lineStyleEN}>Merseyside Police Community Services</p>
                <p className={lineStyleEN}>Merseyside FF Charity Committee</p>
                <br/>
                <p className={lineStyleEN}>Musical Performing Teams:</p>
                <p className={lineStyleEN}>The Torture and the Desert Spiders</p>
                <p className={lineStyleEN}>The Connected (Manchester)</p>
                <p className={lineStyleEN}>The Liverpool Hongkongers Music group</p>
            </div>

            {lang === "EN"? 
            <p className={lineStyleEN}>"Volunteers: Thank you"</p>
            :
            <p className={lineStyleZH}>"義工: 衷心感謝"</p>}

            {lang === "EN"? 
            <p className={lineStyleEN}>We expressed our sincere thanks to all the volunteers and supporters who had contributed their full effort and hardwork to make the event fruitful and successful.</p>
            :
            <p className={lineStyleZH}>友誼節的成功舉行及順利完成，代表著過去两個月來所有義務工作者的默默貢獻、努力付出，有賴著各方面的支持和投入。希望是次活動和交流後，區內香港人可以更加互助互勉，極速融入社區，創造美好生活。</p>
            }

        </>
    )
}

export default Event3Content
