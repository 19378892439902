import React from 'react'
import PDFMovieDes from "../../asset/pdf/ShortFilmFinalistsStorylinesAndDescription2024.pdf"
import useWIndowSize from '../../component/hooks/useWIndowSize'
import PDFView from '../../component/hooks/PDFView'

const MovieDescription = () => {

    const {screenDim} = useWIndowSize()

    return (
        <>
            <PDFView 
                PdfFile={PDFMovieDes}
                fileWidth={screenDim.width*0.9}
            />
        </>
    )
}

export default MovieDescription
