import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import Wrapper from './component/hooks/Wrapper';
// import "../node_modules/react-bootstrap/dist/react-bootstrap"
// import "../node_modules/bootstrap/dist/css/bootstrap.css"

const root = ReactDOM.createRoot(document.getElementById('root'));

//font size of general content
let GeneralStyle = "font-content md:leading-10 xs:leading-1 2xl:text-2xl md:text-2xl xs:text-4xl"


root.render(
  // <React.StrictMode>
  //   <App />
  // </React.StrictMode>

  <Wrapper>
    <div className={GeneralStyle}>
      <App date={Date.now()}/>
    </div>
  </Wrapper>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

