import React from 'react'
import './AboutUsDB.css'

const CompanyBgEN = () => {
  return (
    <>
        <h2>Company Background</h2>
        <p className='AboutUsContent text-justify'>
            Liverpool HongKong Link C.I.C. (LHKL) was incorporated on 16 November 2022. We are a registered Community Interest Company dedicated to providing diverse community services to Hong Kongers in Merseyside Region (Halton, Knowsley, Liverpool, Sefton, St Helens and Wirral), such as adaptability, employability, entrepreneurial training, language skills, inclusion and cross-cultural communication etc. We continue to share positive energy and useful information with the local Hong Kongers, to help them integrate into the community as soon as possible.
        </p>
    </>
  )
}

export default CompanyBgEN
