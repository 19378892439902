import React from 'react'
import PDFView from '../../component/hooks/PDFView'
import HowToSubmitVideo from '../../asset/pdf/HowToSubmitVideo.pdf'
import useWIndowSize from '../../component/hooks/useWIndowSize'

const HowToSubmit = () => {

    const {screenDim} = useWIndowSize()

    return (
        <>
            <PDFView 
                PdfFile={HowToSubmitVideo}
                fileWidth={screenDim.width*0.9}
            />
        </>
    )
}

export default HowToSubmit
