import React from 'react';
import { Routes, Route } from 'react-router-dom';
import VedioManage from '../Userinfo-ManageVideo/VedioManage';
import Content from './component/Content';
import UserinfoNavbar from './component/UserinfoNavbar'; // Make sure to import the navbar component
import HowToSubmit from '../Coverpage-HowToSubmit/HowToSubmit';

const UserInfo = () => {
  return (
    <div>
      <UserinfoNavbar />
      <Routes>
        <Route path="/" element={<VedioManage />} />
        <Route path="HowToSubmit" element={<HowToSubmit />} />
        <Route path="content" element={<Content />} />
      </Routes>
    </div>
  );
};

export default UserInfo;