import React from 'react'
import pdfDirectorBio from '../../asset/pdf/JudgesBio.pdf'
import useWIndowSize from '../../component/hooks/useWIndowSize'
import PDFView from '../../component/hooks/PDFView'

const DirectorBio = () => {

    const {screenDim} = useWIndowSize()

    return (
        <>
            <PDFView 
                PdfFile={pdfDirectorBio}
                fileWidth={screenDim.width*0.9}
            />
        </>
    )
}

export default DirectorBio
