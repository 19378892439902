// import React, {useState} from 'react';
// import { Document, Page } from 'react-pdf';
// import PdfFile from "../../asset/pdf/ShortFilmFestivalPamphlet.pdf"
// //import PdfFile from './asset/ShortFilmFestivalPamphlet.pdf';
// import { pdfjs } from 'react-pdf';
// import "react-pdf/dist/esm/Page/AnnotationLayer.css"
// import useWIndowSize from './useWIndowSize';

// pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

// function PDFView() {
//   const [numPages, setNumPages] = useState(null);
//   const [pageNumber, setPageNumber] = useState(1);
//   const {screenDim} = useWIndowSize()

//   function onDocumentLoadSuccess({numPages}){
//     setNumPages(numPages);
//     setPageNumber(1);
//   }
  
//   return (
//   <div className="PDFView">
//     <center>
//       <div>
//         <Document file={PdfFile} onLoadSuccess={onDocumentLoadSuccess}>
//           {Array.from(
//             new Array(numPages),
//             (el,index) => (
//               <div>
//                 <Page 
//                   width={screenDim.width*0.8}
//                   key={`page_${index+1}`}
//                   pageNumber={index+1}
//                   renderTextLayer={false}
//                 />
//                 <br/>
//               </div> 
//             ) 
//           )}
//         </Document>
//       </div>
//     </center>
//   </div>
//   );
// }

// export default PDFView;

//************************************************* */
import React, {useState} from 'react';
import { Document, Page } from 'react-pdf';
//import PdfFile from "../../asset/pdf/ShortFilmFestivalPamphlet.pdf"
//import PdfFile from './asset/ShortFilmFestivalPamphlet.pdf';
import { pdfjs } from 'react-pdf';
import "react-pdf/dist/esm/Page/AnnotationLayer.css"
import useWIndowSize from './useWIndowSize';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

function PDFView({PdfFile, fileWidth}) {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const {screenDim} = useWIndowSize()

  function onDocumentLoadSuccess({numPages}){
    setNumPages(numPages);
    setPageNumber(1);
  }
  
  return (
  <div className="PDFView">
    <center>
      <div>
        <Document file={PdfFile} onLoadSuccess={onDocumentLoadSuccess}>
          {Array.from(
            new Array(numPages),
            (el,index) => (
              <div key={index}>
                <Page 
                  //width={screenDim.width*0.8}
                  width={fileWidth}
                  key={`page_${index+1}`}
                  pageNumber={index+1}
                  renderTextLayer={false}
                />
                <br/>
              </div> 
            ) 
          )}
        </Document>
      </div>
    </center>
  </div>
  );
}

export default PDFView;