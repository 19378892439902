import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

const Point = (props) => {
  return (
    <>
        <Row className='m-0 p-0'>
            {props.show&&<Col sm={1} md={1} lg={1} xl={1} className='m-0 p-0 flex justify-content-center '>•</Col>}
            <Col sm={11} md={11} lg={11} xl={11} className='m-0 p-0'>
                <p className='text-left rrFont'>{props.content}</p>
            </Col>
        </Row>
    </>
  )
}

export default Point
