import React, { useEffect, useState, useContext, useRef} from 'react';
import { Context } from '../../component/hooks/Wrapper';
import axios from 'axios';
import Accordion from 'react-bootstrap/Accordion';
import VideoManageTable from './componenet/VideoManageTable';
import Form from 'react-bootstrap/Form';
import { useFormik } from 'formik';
import videoSchemaValidation from '../../FormValidation/videoSchemaValidation';
import "./componenet/ManageVideo.css"

const VedioManage = () => {
    //for store all the vedio data from backend
    const context = useContext(Context)
    const [VedioData, setVedioData] = useState(null)
    const [videoManageFeedback, setVideoManageFeedback] = useState({})
    const [feedbackOccur, setFeedbackOccur] = useState(false)
    const [showUploadNewVideo, setShowUploadNewVideo] = useState(false)
    const [showPerviousVideo, setShowPerviousVideo] = useState(false)
    const [firstLoading, setFirstLoading] = useState(0)
    const [accorActKey, setAccorActKey] = useState(null)
    const accordionRefs = useRef([]);

    const formik = useFormik({
        initialValues: {
          author: context.userData.email,
          authorName:context.userData.lastName + ", " + context.userData.firstName,
          youtubeID:"Youtube Vedio ID",
          youtubeLink:"Youtube Video link",
          videoTitle:"Youtube Vedio Title",
          videoDes:"Youtube Vedio Description",
          filmOwner:"(e.g. organization name, Facebook name, Person in charge)",
          category:"",
          status:"Draft",
          publishDate:"",
          areaCode:"44",
          telephone:"We will whatsapp you if you are nominated",
          instagram:"We will promote your instagram on our website",
          crewProducer:"Name of Producer",
          crewDirector:"Name of Director",
          crewEditor:"Name of Editor",
          crewDOP:"Name of Director of Picture"
        },
        validationSchema: videoSchemaValidation,
        // onSubmit: handleOnSubmit
    });

    //for open accordian button 
    const handleAccordianExpand = (data, key) => {
      const filteredData = VedioData.filter(item => item.youtubeID === data);

      const updatedFilterObject = {
        author: filteredData[0].author,
        authorName: context.userData.lastName + ", " + context.userData.firstName,
        youtubeID: filteredData[0].youtubeID,
        youtubeLink:filteredData[0].youtubeLink,
        videoTitle: filteredData[0].videoTitle,
        videoDes: filteredData[0].videoDes,
        filmOwner: filteredData[0].filmOwner,
        category: changeIndexToCategory(filteredData[0].category),
        publishDate: filteredData[0].publishDate,
        status: filteredData[0].status,
        areaCode: filteredData[0].areaCode,
        telephone: filteredData[0].telephone,
        instagram: filteredData[0].instagram,
        crewProducer: filteredData[0].crewProducer,
        crewDirector: filteredData[0].crewDirector,
        crewEditor: filteredData[0].crewEditor,
        crewDOP: filteredData[0].crewDOP,
      };
      formik.setValues(updatedFilterObject);
      //setNewData(updatedFilterObject)
      setAccorActKey(key)
    }

    //for open new video accordian button
    const handleNewAccordianExpand = () => {

      // //empty the from for submit new video
      // const emptyState = {
      //   author: context.userData.email,
      //   authorName: context.userData.lastName + ", " + context.userData.firstName,
      //   youtubeID: "",
      //   youtubeLink: "",
      //   videoTitle: "",
      //   videoDes: "",
      //   filmOwner: "",
      //   category: "",
      //   publishDate: "",
      //   status: "",
      //   areaCode: "",
      //   telephone: "",
      //   instagram: "",
      //   crewProducer: "",
      //   crewDirector: "",
      //   crewEditor: "",
      //   crewDOP: "",
      // }

      // formik.setValues(emptyState);
      setAccorActKey("-99")
    } 

    //for geting video data from backend
    const handleGetVideo = async(newData) => {
      axios.get(`${context.baseURL}video/`, { headers: context.token })
      .then(response => {
        setVedioData(response.data);
        console.log('get video data', response.data)
      }).catch(error => {
        setVideoManageFeedback(Object.entries(error.response.data))
      })
    }

    //for getting data in the begining
    useEffect(()=>{
      if(VedioData===null){
        if (firstLoading<=10){  
          handleGetVideo()
          setFirstLoading(prev=>prev+1)
          console.log("get data by net", firstLoading, VedioData)
        }else{
          console.log("get data no net", firstLoading, VedioData)
        }
      }
    },[firstLoading])

    //for getting data for each change
    useEffect(()=>{
      handleGetVideo()
      //console.log("2 useEffect",context.reload, VedioData)
      //console.log("2", {VedioData}, VedioData===null)
    },[context.reload])

    //decide showing upload video part or not, the control variabe called "ShowUploadNewVideo"
    //decide showing pervious upload video or not, the control variabe called "ShowPerviousVideo"
    useEffect(()=>{
      if (VedioData===null){
        setShowUploadNewVideo(true)
        setShowPerviousVideo(false)
        //console.log("upload video  no loading", VedioData, showUploadNewVideo)
      } else if (Object.entries(VedioData).length <3) {
        setShowUploadNewVideo(true)
        setShowPerviousVideo(true)
        //console.log("upload video  no loading", VedioData," showUploadNewVideo)
      } else {
        setShowUploadNewVideo(false)
        setShowPerviousVideo(true)
        //console.log("upload video no loading", VedioData, showUploadNewVideo)
      }
    },[VedioData])
   
    //for changing the feedbackOccur state
    useEffect(()=>{
      if (Object.keys(videoManageFeedback).length === 0){
        setFeedbackOccur(false)
      }else(
        setFeedbackOccur(true)
      )
    }, [feedbackOccur, videoManageFeedback])

    //change the category index into value
    const changeIndexToCategory = (data) => {
      let indexCategory = ""
      if (data === 1){
          indexCategory = "Drama / Scripted"
      } else if (data === 2){
          indexCategory = "Avant-Grade"
      } else if (data === 3){
          indexCategory = "Documentary"
      } else if (data === 4){
          indexCategory = "Animated"
      } else if (data === 5){
          indexCategory = "Live-Action / Narrative"
      }

      return(indexCategory)
    }

    //find the latest key for accordance
    useEffect(() => {
      if (VedioData === null) {
        //console.log(VedioData === null)
        setAccorActKey("-99")
        //console.log(accorActKey, context.setNumSubmit)
      } else if (context.firstSubmitVedio === true && VedioData.length < 3) {  
        //console.log(context.firstSubmitVedio === true && VedioData.length < 3)
        setAccorActKey("-99")
        //console.log(accorActKey, context.setNumSubmit)
      } else if (context.firstSubmitVedio === true && VedioData.length === 3) {  
        //console.log(context.firstSubmitVedio === true && VedioData.length === 3)
        //setAccorActKey(parseInt(VedioData.length - 1))
        setAccorActKey("4")
        //console.log(accorActKey, context.setNumSubmit)
      } else if (context.firstSubmitVedio === false) {
        //console.log(context.firstSubmitVedio === false)
        setAccorActKey("4")
        //setAccorActKey(parseInt(VedioData.length - 1))
        //accordionRefs.current[VedioData.length - 1].click()
        //console.log(accorActKey, VedioData.length, VedioData.length -1, context.numSubmit)
        //reloadAccordianData(accorActKey)
        //handleAccordianExpand(VedioData[parseInt(String(VedioData.length - 1))].youtubeID, parseInt(String(VedioData.length - 1)))
        //console.log(VedioData[accorActKey].youtubeID)
      }
    }, [context.firstSubmitVedio, context.numSubmit]) 
    
    // //for reloading data
    // const reloadAccordianData = async (data) => {
    //   for (let i=0; i<=10; i++){
    //     console.log("reload count", i)
    //     if (VedioData[data] != null){
    //       console.log("reload count", i, VedioData[data])
    //       handleAccordianExpand(VedioData[data].youtubeID, data)
    //       break;
    //     }else{
    //       await sleep(200);
    //       handleGetVideo()  // Wait for 0.2 seconds
    //       console.log("reload count", i, VedioData[data])
          
    //     }
    //   }
    // }

    // function sleep(ms) {
    //   return new Promise(resolve => setTimeout(resolve, ms));
    // }

  return (
    <>  
      {/* <Accordion defaultActiveKey={accorActKey}> */}
      <Accordion activeKey={accorActKey}>
        {/* posting a new video */}
          {showUploadNewVideo?
            <Accordion.Item eventKey="-99">
              <Accordion.Header className="accordianButton" onClick={() => handleNewAccordianExpand()}>Click here to submit new Video</Accordion.Header>
              <Accordion.Body  className="p-0 p-0.5">
                <VideoManageTable 
                  formPurpose="submitVideo"
                  formik={formik}
                  showVideo={false}
                  //youtube link
                  nameYoutubeLink="youtubeLink"
                  disableYoutubeLink={true}
                  //film category
                  nameCategoryID="category"
                  disabledCategory={true}
                  //publish date
                  namePublishDate="publishDate"
                  disabledPublishDate={true}
                  //status
                  nameStatus="status"
                  disabledStatus={true}
                  //video Title
                  nameVideoTitle="videoTitle"
                  disabledVideoTitle={true}
                  //Video description
                  nameVideoDescription="videoDes"
                  disabledVideoDescription={true}
                  //Film Owner
                  nameFilmOwner="filmOwner"
                  disableFilmOwner={true}
                  //Instagram
                  nameInstagram="instagram"
                  disableInstagram={true}
                  //Producer
                  nameProducer="crewProducer"
                  disableProducer={true}
                  //Editor
                  nameEditor="crewEditor"
                  disableEditor={true}
                  //DOP
                  nameDOP="crewDOP"
                  disableDOP={true}
                  //user name
                  nameArthor="authorName"
                  disableArthor={true}
                  //user email
                  nameArthorEmail="author"
                  disableArthorEmail={true}
                  //area code
                  nameAreaCode="areaCode"
                  disableAreaCode={true}
                  // telephone
                  nameTelephone="telephone"
                  disableTelephone={true}
                  //Button
                  activeSubmitVideoButton={false}
                  activeDeleteButton={false}
                  activeUpdateButton={false}
                />
              </Accordion.Body>
            </Accordion.Item>
          :""}

        {/* list out all the current video */}

        {showPerviousVideo?
          VedioData.map((item, key) => (
            <Accordion.Item eventKey={key} key={key}>
              {/* <Accordion.Header className="accordianButton"  onClick={() => handleAccordianExpand(item.youtubeID, key)}>{accorActKey} {key} Submitted Video, {item.videoTitle}</Accordion.Header> */}
              <Accordion.Header className="accordianButton" ref={(el) => accordionRefs.current[key] = el} onClick={() => handleAccordianExpand(item.youtubeID, key)}> Submitted Video, {item.videoTitle}</Accordion.Header>
              <Accordion.Body className="p-0 p-0.5">
                <VideoManageTable 
                  formPurpose="editVideo"
                  formik={formik}
                  showVideo={true}
                  //youtube link
                  nameYoutubeLink="youtubeLink"
                  disableYoutubeLink={true}
                  //film category
                  nameCategoryID="category"
                  disabledCategory={true}
                  //publish date
                  namePublishDate="publishDate"
                  disabledPublishDate={true}
                  //status
                  nameStatus="status"
                  disabledStatus={true}
                  //video Title
                  nameVideoTitle="videoTitle"
                  disabledVideoTitle={true}
                  //Video Description
                  nameVideoDescription="videoDes"
                  disabledVideoDescription={true}
                  //Film Owner
                  nameFilmOwner="filmOwner"
                  disableFilmOwner={true}
                  //Instagram
                  nameInstagram="instagram"
                  disableInstagram={true}
                  //Producer
                  nameProducer="crewProducer"
                  disableProducer={true}
                  //Editor
                  nameEditor="crewEditor"
                  disableEditor={true}
                  //Director of picture
                  nameDOP="crewDOP"
                  disableDOP={true}
                  //Author Name
                  nameArthor="authorName"
                  disableArthor={true}
                  //Author email
                  nameArthorEmail="author"
                  disableArthorEmail={true}
                  //telephone area code
                  nameAreaCode="areaCode"
                  disableAreaCode={true}
                  //telephone
                  nameTelephone="telephone"
                  disableTelephone={true}
                  //Button
                  activeSubmitVideoButton={false}
                  activeDeleteButton={context.userData.isStaff? false:true}
                  activeUpdateButton={false}
                />
              </Accordion.Body>
            </Accordion.Item>
            
          ))
        :
          ""}
      </Accordion>
    </>
  )
}

export default VedioManage
