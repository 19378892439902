import React, {useState, createContext} from 'react'
import { IntlProvider } from 'react-intl'
import English from '../../lang/en-GB.json'
import Chinese from "../../lang/zh-HK.json"
import baseURL from "../../asset/database/url.json"

//create context for this app
export const Context = createContext()

//get the language of the user
const local = navigator.language

//change the default language depends of user browser language
let lang //degind the languae variable

if(String(navigator.language).includes("en")){
  lang = English
}else{
  lang = Chinese
}

const Wrapper = (props) => {
    //locale is the language of the user browser
    const [locale, setLocale] = useState(local)
    //for translate chinese to english
    const [messages, setMessages] = useState(lang)
    //calculate the navbar height
    const [navbarHeight, setNavbarHeight] = useState(0)
    const [noNavbarHeight, setNoNavbarHeight] = useState(0)
    //filter for the short film navbar
    const [shortFilmFilter, setShortFilmFilter] = useState("All")
    //for token
    const [token, setToken] = useState()
    const [loginStatus, setLoginStatus] = useState(false)
    //get user data
    const [userData, setUserData] = useState({  email:'',
                                                firstName:'',
                                                lastName:'',
                                                userName:'',
                                                isStaff:'',
                                                isActivate:'',
                                                })
    //pass the data from select events to blank pages
    const [pageDetails, setPageDetails] = useState({
                                                        img:"",
                                                        title:"",
                                                        content:""
                                                    })
    //reload when upload video or update video or delet video
    const [reload, setReload] = useState(1)
    const [firstSubmitVedio, setFirstSubmitVedio] = useState(true)
    const [numSubmit, setNumSubmit] = useState(0)

    return (
        <>
            <React.StrictMode>
                <Context.Provider value={{  locale, setLocale, 
                                            messages, setMessages, 
                                            navbarHeight, setNavbarHeight, 
                                            noNavbarHeight, setNoNavbarHeight, 
                                            shortFilmFilter, setShortFilmFilter, 
                                            token, setToken,
                                            loginStatus, setLoginStatus,
                                            userData, setUserData,
                                            pageDetails, setPageDetails,
                                            reload, setReload,
                                            firstSubmitVedio, setFirstSubmitVedio,
                                            numSubmit, setNumSubmit,
                                            baseURL
                                        }}>

                    <IntlProvider messages={messages} locale={locale}>
                        {props.children}
                    </IntlProvider>
                </Context.Provider>
            </React.StrictMode>
        </>
    )
}


export default Wrapper
