import React, {useState, useEffect} from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Heading from './template/Heading'
import Point from './template/Point'
import useWIndowSize from '../../component/hooks/useWIndowSize'

const FilmRulesZH = () => {

  const [show, setShow] = useState(true)
  const {screenDim} = useWIndowSize()

  useEffect(()=>{
    if (screenDim.width >= 580){
      setShow(true)
    } else if (screenDim.width < 580){
      setShow(false)
    }}
    ,[screenDim.width]
  )

  return (
    <>
      <Container Fluid>
        <Row>
          <h2 className='text-left underline font-bold'>利港連線利物浦跨文化傳播短片節規則</h2>
        </Row>
        <br/>
        <Heading content={'影片長度：'}/>
        <Point show={show} content={'提交的短片長度不得超過20分鐘。'}/>
        <br/>
        <Heading content={'主題：'}/>
        <Point show={show} content={'短片的主題應圍繞“多元文化交流”。'}/>
        <Point show={show} content={'影片可以探討文化多樣性、跨文化理解、代溝或任何其他與多元文化交流相關的主題。'}/>
        <br/>
        <Heading content={'評審團：'}/>
        <Point show={show} content={'將邀請來自相關領域的國際專業人士、獨立制片人，著名電影導演 、文化人擔任評審團成員。'}/>
        <Point show={show} content={'評審團將根據故事情節、攝影、表演、創意和主題相關性等標準評估提交的影片。'}/>
        <Point show={show} content={'電影製片人將作為首輪短片初選的顧問。'}/>
        <Point show={show} content={'最終選出20部短片的評審團成員有4-5位。'}/>
        <br/>
        <Heading content={'影片選拔：'}/>
        <Point show={show} content={'在收到的所有提交作品中，評審團將選出最佳的20部短片。'}/>
        <Point show={show} content={'所選影片將在利物浦劇院放映。'}/>
        <br/>
        <Heading content={'提交日期：'}/>
        <Point show={show} content={'短片的提交期將於2023年9月15日開始。'}/>
        <Point show={show} content={'所有作品必須在2023年11月30日 GMT 23:55截止前提交。'}/>
        <br/>
        <Heading content={'提交方式：'}/>
        <Point show={show} content={'參加者需要在官方網站登記及填寫表格，有關提交流程的詳細信息可在利港連線的官方網站(http://www.lhkl.org.uk)和Facebook專頁找到。'}/>
        <Point show={show} content={'製片人把作品上載自己的YouTube 戶口，初步入選作品均會於官方YouTube playlist 以hyperlink 展示，可以參考官方資訊，了解如何提交他們的影片的指南。'}/>
        <Point show={show} content={'建議使用HD 1080p (1920×1080) ProRes 422 HQ MOV video file MP4格式，長寬比為16:9。'}/>
        <Point show={show} content={'進入總決賽的二十部作品需另外上戴到指定伺服器配合電影院放映的格式，入圍影片需要提供技術格式（DCP格式）進行電影院放映轉換。'}/>
        <br/>
        <Heading content={'參賽規則和截止日期：'}/>
        <Point show={show} content={'利港連線保留在必要時修改參賽規則和截止日期的權利。'}/>
        <Point show={show} content={'參賽者應定期查看官方網站和Facebook專頁以了解任何更新或變更。'}/>
        <Point show={show} content={'提交的短片必須在2019年後製作（即製作不超過3年6 個月）。'}/>
        <Point show={show} content={'非英語語言影片需要提供英文字幕。'}/>
        <Point show={show} content={'最多提交3 套作品。'}/>
        <br/>
        <Heading content={'版權：'}/>
        <Point show={show} content={'所有提交的作品版權屬於製片人，放映線上或劇院後，主辦方將不保留任何副本，除了用於宣傳目的的30秒預告片。'}/>
        <br/>
        <Point show={false} content={'請注意，上述規定是根據提供的信息所編制的概要。建議查閱官方網站和Facebook專頁以獲取最準確和最新的提交指南和詳細信息。'}/>
      </Container>
        

        

    </>
  )
}

export default FilmRulesZH
