import React from 'react'
import './AboutUsDB.css'

const ProjectZH = () => {
  return (
    <>
        <h2 className="Contenttitle">項目簡介</h2>

        <h3 className="Contenttitle">1. 就業規劃</h3>

        <p className='AboutUsContent'>
            我們與Merseyside Community Training合作，舉辦就業心態及規劃研討會、技能培訓和創業工作坊等，協助默西塞德郡內的香港人提升技能，了解工作要求及入職準備。
        </p>

        <h3 className="Contenttitle">2. 利物蒲多元文化短片節</h3>

        <p className='AboutUsContent'>
            我們將於2024年初舉辦首屆利物浦多元文化短片節，邀請國際獨立電影制作人，業餘電影工作者，電影及媒體學生展示各類短片，推動短片文化及促進多元文化交流，於九月中開始接受全球作品參加，由英國，歐洲及香港電影專業人仕及經驗豐富影展評委選出二十部出色作品，最佳作品除了可得獎狀及獎金外，並於2024年初全球網上及英國劇埸放映。
        </p>
    </>
  )
}

export default ProjectZH
