// import React, {useState, useEffect} from 'react'
// import ChangePasswordPic from "../../asset/pic/ChangePassword.jpeg"
// import AboutUsTemplate from '../AboutUS/AboutUsTemplate'
// import useWIndowSize from '../../component/hooks/useWIndowSize'
// import Container from 'react-bootstrap/Container';
// import ResetPasswordTable from './ResetPasswordTable'

// const ResetPassword = () => {

//     const {screenDim} = useWIndowSize()
//     const [showImage, setShowImage] = useState(true)

//     useEffect(()=>{
//         if (screenDim.width >= 900){
//           setShowImage(true)
//         } else if (screenDim.width < 900){
//           setShowImage(false)
//         }}
//         ,[screenDim.width]
//       )
    

//     return (
//         <>
          
//             <Container fluid>
//                 <AboutUsTemplate show={showImage} pic={ChangePasswordPic} content={<ResetPasswordTable/>}/>
//             </Container>
//         </>
//     )
// }

// export default ResetPassword

//*************************** */
import React, { useState, useEffect, useContext } from 'react';
import ChangePasswordPic from "../../asset/pic/ChangePassword.jpeg";
import AboutUsTemplate from '../AboutUS/AboutUsTemplate';
import useWIndowSize from '../../component/hooks/useWIndowSize';
import Container from 'react-bootstrap/Container';
import ResetPasswordTable from './ResetPasswordTable';
import { useParams } from 'react-router-dom';
import { Context } from '../../component/hooks/Wrapper';

const ResetPassword = () => {
    const { screenDim } = useWIndowSize();
    const [showImage, setShowImage] = useState(true);
    const [content, setContent] = useState(null);
    const { uid, token } = useParams();
    const context = useContext(Context)

    useEffect(() => {
        if (screenDim.width >= 900) {
            setShowImage(true);
        } else if (screenDim.width < 900) {
            setShowImage(false);
        }
    }, [screenDim.width]);   

    return (
        <>
            <Container fluid>
                <AboutUsTemplate show={showImage} pic={ChangePasswordPic} content={<ResetPasswordTable />}/>
            </Container>           
        </>
    );
}

export default ResetPassword;
