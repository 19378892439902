// import {c:\Users\lamki\OneDrive\桌面\demo - final\src\database\ContactData.js} from "./ContactData"
import React from 'react'
import act1 from '../pic/mainpage/activaties/act1.jpeg'
import act2 from '../pic/mainpage/activaties/act2.jpg'
import act3 from '../pic/mainpage/activaties/act3_1.jpeg'

import Event1description from "../database/eventsDescription/event1/Event1description"
import Event2description from './eventsDescription/event2/Event2description';
import Event3description from './eventsDescription/event3/Event3description';
import Event3Content from './eventsDescription/event3/Event3Content';

export const AllEvents= [
	{
		id: 1,
		titleEN: "Employment, Retirement, Start a Business",
		titleZH: "就業，退休, 創業",
		descriptionEN:<Event1description/>,
		descriptionZH:<Event1description/>,
		Location: "app.liverpool",
		start: new Date("2023-08-18"),
		end: new Date("2023-08-18"),
		openTime:"09:30:00",
		closeTime:"12:30:00",
		repeat:0,
		removeDate:[],
		additionalDate:[],
		img:act1,
		Address: <div>
					<p>TBC</p>
				</div>,
		feeEN: "Free",
		feeZH: "免費",
	},
	{
		id: 2,
		titleEN: "LHKL Inauguration Event cum Career Workshop and launch of Liverpool Short Film Festival 2024",
		titleZH: "利港連線就職典禮暨職業工作坊及利物浦短片電影節啟動禮2024",
		descriptionEN:<Event2description/>,
		descriptionZH:<Event2description/>,
		Location: "app.liverpool",
		start: new Date("2023-08-18"),
		end: new Date("2023-08-18"),
		openTime:"09:30:00",
		closeTime:"12:30:00",
		repeat:0,
		removeDate:[],
		additionalDate:[],
		img:act2,
		Address: <div>
					<p>Meeting Room 1&2,</p> 
					<p>Liverpool Central Library</p>
					<p>William Brown St,</p>
					<p>Liverpool</p> 
					<p>L3 8EW</p>
				</div>,
		feeEN: "Free",
		feeZH: "免費",
	},
	{
		id: 3,
		titleEN: "Past Event:Friendship Festival",
		titleZH: "過去活動：友誼節",
		descriptionEN:<Event3Content/>,
		descriptionZH:<Event3Content/>,
		Location: "app.liverpool",
		start: new Date("2023-02-04"),
		end: new Date("2023-02-04"),
		openTime:"11:00:00",
		closeTime:"17:00:00",
		repeat:0,
		removeDate:[],
		additionalDate:[],
		img:act3,
		Address: <div>
					<p>St Bride's Church</p> 
					<p>Percy Street</p>
					<p>Liverpool</p> 
					<p>L3 7LT</p>
				</div>,
		feeEN: "Free",
		feeZH: "免費",
	},
];
