// import * as yup from "yup"

// const SubmitSchema = yup.object().shape({
//     firstName: yup.string().max(15, "max 15 character").required("Please enter first name"),
//     lastName: yup.string().max(15, "max 15 character").required("Please enter last name"),
//     userName: yup.string().max(15, "max 15 character").required("Please enter username"),
//     email: yup.string().email("Invalid email format").required("Please enter email"),
//     password: yup.string().min(8, "Password must be at least 8 characters long").required("Please enter password"),
    
// });

import * as Yup from 'yup';

const validationSchema = Yup.object({
    username: Yup.string().max(15, "max 15 character").required('Username is required'),
    firstName: Yup.string().max(15, "max 15 character").required('First Name is required'),
    lastName: Yup.string().max(15, "max 15 character").required('Last Name is required'),
    email: Yup.string().email('Invalid email format').required('Email is required'),
    password: Yup.string().min(8, 'Password must be at least 8 characters').required('Password is required'),
    confirmPassword: Yup.string().oneOf([Yup.ref('password')], 'Passwords must match').required('Please re-enter your password')
});

export default validationSchema