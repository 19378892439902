import * as Yup from 'yup';
import "yup-phone"
import filmCategory from "../asset/database/VideoCategory.json"

const videoSchemaValidation = Yup.object({
    youtubeID:Yup.string().required('Youtube is required'),
    youtubeLink:Yup.string().required('Youtube is required'),
    videoTitle:Yup.string().max(100, "max 100 character").required('Video Title is required'),
    videoDes:Yup.string().max(500, "max 500 character").required('Video Description is required'),
    filmOwner:Yup.string().max(30, "Film Owner max 30 character").required('Film Owner name is required (e.g. Priducer Name, Facebook name, Company Name)'),
    //category:Yup.string().required("Please select a film category"),
    category: Yup.string()
    .oneOf(filmCategory, 'Please select a valid film category')
    .required('Please select a film category'),
    
    areaCode:Yup.string().length(5).matches(/^[0-9]{5}/),
    telephone:Yup.string().length(15).matches(/^[0-9]{5}/),
    instagram:Yup.string().max(30, "instagram max 30 character"),
    crewProducer:Yup.string().max(30, "max 30 character"),
    crewDirector:Yup.string().max(30, "max 30 character"),
    crewEditor:Yup.string().max(30, "max 30 character"),
    crewDOP:Yup.string().max(30, "max 30 character"),
    publishDate: Yup.date()
    .min(new Date(2019, 5, 29), "Date must be on or after June 30, 2019")
    .required('Publish Date is required'),
});

export default videoSchemaValidation