import React, {useContext, useEffect, useState} from 'react'
import FilmRulesEN from './FilmRulesEN'
import FilmRulesZH from './FilmRulesZH'
import { Context } from '../../component/hooks/Wrapper'
import "./FilmRules.css"

const FilmRules = () => {

    const context = useContext(Context)
    const [lang, setLang] = useState("")

    useEffect(() => {
        // Scroll to the top of the page when the component mounts
        window.scrollTo(0, 0);
      }, []);
    
    useEffect(()=>{
        String(context.locale).includes("en")? setLang("EN"):setLang("ZH") 
      },[context.locale])

    return (
        <>
            {lang === "ZH"? <FilmRulesZH/>:<FilmRulesEN/>}
        </>
    )
}

export default FilmRules
