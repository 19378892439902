import React, {useContext, useEffect, useState} from 'react'
import { Context } from '../../component/hooks/Wrapper';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Container } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { AllEvents } from '../../asset/database/EventsData';
import CardsHorizontal from './template/CardsHorizontal';
import JobEventsNavbar from './JobEventsNavbar';

export const JobEvents = () => {

  const context = useContext(Context)

  const [lang, setLang] = useState("")

  useEffect(()=>{
    String(context.locale).includes("en")? setLang("EN"):setLang("ZH")  
  },[context.locale])


  return (
    <>
        <JobEventsNavbar/>

        <Container fluid>
            <Row>
            {AllEvents.map((x) => (
                <Col xs={12} md={12} lg={12} xl={12} xxl={12} className="mb-4">
                  <CardsHorizontal 
                    img={x.img} 
                    title={lang === "ZH"? x.titleZH : x.titleEN} 
                    content={x.descriptionEN}
                    startDate={x.start}
                    endDate={x.end}
                    openTime={x.openTime}
                    closeTime={x.closeTime}
                    buttonText={<FormattedMessage 
                                  id="app.clickViewMore" 
                                  defaultMessage="Click to view more"/>}
                  />
                </Col>
            ))}
            </Row>
        </Container>
        <br/>

    </>
  )
}

export default JobEvents
