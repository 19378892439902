import React, {useState, useEffect, useContext} from 'react'
import Container from 'react-bootstrap/Container';
import pic1 from '../../asset/pic/AboutUsPic1.jpeg'
import pic2 from '../../asset/pic/AboutUsPic2.jpeg'
import CompanyBgZH from './Content/CompanyBgZH';
import CompanyBgEN from './Content/CompanyBgEN';
import ProjectEN from './Content/ProjectEN';
import ProjectZH from './Content/ProjectZH';
import useWIndowSize from '../../component/hooks/useWIndowSize';
import { Context } from '../../component/hooks/Wrapper';
import AboutUsTemplate from './AboutUsTemplate';

const AboutUs = () => {

  const {screenDim} = useWIndowSize()
  const [showImage, setShowImage] = useState(true)
  const context = useContext(Context)
  const [lang, setLang] = useState("")

  useEffect(()=>{
    if (screenDim.width >= 900){
      setShowImage(true)
    } else if (screenDim.width < 900){
      setShowImage(false)
    }}
    ,[screenDim.width]
  )

  useEffect(()=>{
    String(context.locale).includes("en")? setLang("EN"):setLang("ZH")  
  },[context.locale])

  return (
    <>
      <Container fluid>
        <AboutUsTemplate show={showImage} pic={pic1} content={lang === "ZH"? <CompanyBgZH/>:<CompanyBgEN/>}/>
        <AboutUsTemplate show={showImage} pic={pic2} content={lang === "ZH"? <ProjectZH/>:<ProjectEN/>}/>
      </Container>
    </>
  )
}

export default AboutUs
