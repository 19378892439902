export const messagesZH = {
    allDay: "全日",
    previous: "上一個",
    next: "下一個",
    today: "今天",
    month: "今天",
    week: "週",
    day: "日",
    agenda: "行程",
    date: "日期",
    time: "時間",
    event: "活動",
};