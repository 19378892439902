import React from 'react'
import './AboutUsDB.css'

const CompanyBgZH = () => {
  return (
    <>
        <h2 className="Contenttitle">公司簡介</h2>
        <p className='AboutUsContent text-justify'>
            利港連線C.I.C. (LHKL) 於2022年11月16日成立。 我們是一間在英國註冊的社區利益公司，致力為居住在默西賽德郡（荷頓、諾斯利、利物浦、塞夫頓、聖海倫斯和偉盧）的香港人提供多元化的社區服務，例如適應力、就業能力、創業培訓、語言技能、包容及跨文化交流等。 我們持續與香港人分享正能量和正面信息，以協助他們能盡快適應和融入社區。
        </p>
    </>
  )
}

export default CompanyBgZH
