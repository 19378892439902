import React from 'react'
import Row from 'react-bootstrap/Row'

const Heading = (props) => {
  return (
    <>
        <Row className='m-0 p-0'>
            <h2 className='text-left m-0 p-0 rrFont'>{props.content}</h2>
        </Row>
    </>
  )
}

export default Heading
