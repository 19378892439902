import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import './Logo.css'

const Logo = () => {

    const logoEnglishFontStyle = "m-0 d-flex justify-content-center align-items-center english-font"
    const logoChineseFontStyle = "m-0 d-flex justify-content-center align-items-center english-font"
    const logoColStyle = "p-0"
    const logoColWidth = 3

    return (
        <>
            <Container className="p-0">
                <Col className='m-0'>
                    <Row className='no-gutters m-0'>
                        <Col xs={logoColWidth} className={logoColStyle}>
                            <Row className={logoEnglishFontStyle}>L</Row>
                            <Row className={logoChineseFontStyle}>利</Row>
                        </Col>
                        <Col xs={logoColWidth} className={logoColStyle}>
                            <Row className={logoEnglishFontStyle}>H</Row>
                            <Row className={logoChineseFontStyle}>港</Row>
                        </Col>
                        <Col xs={logoColWidth} className={logoColStyle}>
                            <Row className={logoEnglishFontStyle}>K</Row>
                            <Row className={logoChineseFontStyle}>連</Row>
                        </Col>
                        <Col xs={logoColWidth} className={logoColStyle}>
                            <Row className={logoEnglishFontStyle}>L</Row>
                            <Row className={logoChineseFontStyle}>線</Row>
                        </Col>
                    </Row>
                </Col>
            </Container>
        </>
    )
}

export default Logo
