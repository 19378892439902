import React, {useContext} from 'react'
import { Context } from './component/hooks/Wrapper'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
// import GetData from './pages/GetData'
import NavigationBar from './component/navbar/NavigationBar'
import LoginPage from './pages/Login/LoginPage'
import ShortFilm from './pages/Coverpage/ShortFilm'
import AboutUs from './pages/AboutUS/AboutUs'
import Logout from './pages/Login-Logout/Logout'
import Footer from './component/footer/Footer'
import ShortListFilms from './pages/Coverpage-ShortListFilm/ShortListFIlm'
import './App.css'
import RegisterUser from './pages/Login-RegisterUser/RegisterUser'
import ForgetPassword from './pages/Login-ForgetPassword/ForgetPassword'
import JobEventsCalender from './pages/Activate-JobEventsCalender/JobEventsCalender'
import JobEvents from './pages/Activate-JobEvents/JobEvents'
import FilmRules from './pages/Coverpage-FilmRule/FilmRules'
import HowToSubmit from './pages/Coverpage-HowToSubmit/HowToSubmit'
import EventDetails from './pages/Activate-EventDetails/EventDetails'
import UserInfo from './pages/UserInfo/UserInfo'
import { Helmet } from 'react-helmet'
import logo from './asset/pic/LHKL_logo_S.jpg'
import currentURL from './asset/database/currentURL.json'
import webDescription from './asset/database/webDesription.json'
import webTitle from './asset/database/webTitle.json'
import Event2description from './asset/database/eventsDescription/event2/Event2description'
import AlertBar from './component/alertBar/AlertBar'
import ResetPassword from './pages/Admin-ResetPassword/ResetPassword'
import MovieDescription from './pages/Movie-description/MovieDescription'

import DirectorBio from './pages/Coverpage-DirectorBio/DirectorBio'

const App = () => {

  const context = useContext(Context)
  
  return (
    <>
      <Helmet>
        <title>LHKL</title>
        <meta name="description" content={webDescription} />
        <meta name="author" content={webTitle} />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="robots" content="index, follow" />
        <meta charset="UTF-8" />
        <meta property="og:title" content={webTitle} />
        <meta property="og:description" content={webDescription} />
        <meta property="og:image" content={logo} />
        <meta property="og:url" content={currentURL} />
        <meta name="twitter:title" content={webTitle} />
        <meta name="twitter:description" content={webDescription} />
        <meta name="twitter:image" content={logo} />

        {/* Add more meta tags as needed */}
      </Helmet>

      <Router>
        <NavigationBar />
        <AlertBar/>
        <Routes>
          <Route path="/" element={<AboutUs />}/>
          <Route path="/ShortFilmFestival" element={<ShortFilm />}/>
          <Route path="/ShortListFilm" element={<ShortListFilms/>}/>
          <Route path="/AboutUs" element={<AboutUs/>}/>
          <Route path="/Login" element={<LoginPage />}/>
          {/* <Route path="/GetData" element={<GetData/>}/> */}
          <Route path="/Register" element={<RegisterUser/>}/>
          <Route path="/ForgetPassword" element={<ForgetPassword/>}/>
          <Route path="/JobEventsCalender" element={<JobEventsCalender/>}/>
          <Route path="/JobEvents" element={<JobEvents/>}/>
          <Route path="/RulesAndRegulations" element={<FilmRules/>}/>
          <Route path="/HowToSubmit" element={<HowToSubmit/>}/>
          <Route path="/eventDetails" element={<EventDetails/>}/>
          <Route path="/OpenCeremony" element={<Event2description/>}/>
          <Route path="/DirectorBio" element={<DirectorBio/>}/>

          <Route path="/MovieDes" element={<MovieDescription/>}/>

          {/* <Route path="/VedioManage" element={context.loginStatus === true? <VedioManage/> : <LoginPage/>}/>  */}
          <Route path="/Logout" element={context.loginStatus === true? <Logout/> : <LoginPage/>}/>
          <Route path="/UserInfo/*" element={context.loginStatus === true? <UserInfo/> : <LoginPage/>}/>
          {/* <Route path="/ResetPassword" element={<ResetPassword/>}/> */}
          <Route path="/ResetPassword/user/password/reset/confirm/:uid/:token" element={<ResetPassword/>} />
          {/* {context.loginStatus === true? <Route path="/VedioManage" element={<VedioManage />}/> : ""}
          {context.loginStatus === true? <Route path="/Logout" element={<Logout/>}/> : ""}
          {context.loginStatus === true? <Route path="/UserInfo" element={<UserInfo/>}/> : ""} */}
        </Routes>
        <Footer/>
      </Router>
    </>
  )
}

export default App
