import React, {useContext, useState, useEffect} from 'react'
import { Context } from '../../../component/hooks/Wrapper';
import { useNavigate } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import TableInputTemplate from './TableInputTemplate';
import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';
import VideoComponent from '../../Coverpage-ShortListFilm/component/Video/template/VideoComponent'
import videoSchemaValidation from '../../../FormValidation/videoSchemaValidation';
import Form from 'react-bootstrap/Form';
import Accordion from 'react-bootstrap/Accordion';
import axios from 'axios';
import filmCategoty from '../../../asset/database/VideoCategory.json'

const VideoManageTable = (props) => {

    const context = useContext(Context)
    const [videoManageFeedback, setVideoManageFeedback] = useState({})
    const [disableButton, setDisableButton] = useState(false) //check are the input valid
    const [feedbackOccur, setFeedbackOccur] = useState(false)
    const [newYoutubeID, setNewYoutubeID] = useState("")
    const [actChangeYoutubeID, SetActChangeYoutubeID] = useState(0)
    const emptyState = {
                        author: context.userData.email,
                        authorName: context.userData.lastName + ", " + context.userData.firstName,
                        youtubeID: "",
                        youtuneLink: "",
                        videoTitle: "",
                        videoDes: "",
                        filmOwner: "",
                        category: "",
                        publishDate: "",
                        status: "",
                        areaCode: "",
                        telephone: "",
                        instagram: "",
                        crewProducer: "",
                        crewDirector: "",
                        crewEditor: "",
                        crewDOP: "",
                    }

    //check all the input data valid or not
    useEffect(()=>{
        if( String(props.formik.errors.youtubeID) === "undefined" &&
            String(props.formik.errors.videoTitle) === "undefined" &&
            String(props.formik.errors.videoDes) === "undefined" &&
            String(props.formik.errors.filmOwner) === "undefined" &&
            String(props.formik.errors.category) === "undefined" &&
            String(props.formik.errors.publishDate) === "undefined"
        ) {
            setDisableButton(false)
        } else {
            setDisableButton(true)
        }
    },[props.formik])

    //for posting new video on to the website
    const handlePost = async(data) => {
        //if there are no telephone number is enter, change it to empty string
        if (data.telephone === "We will whatsapp you if you are nominated") {
            data={...data, telephone:""}
        }
        //change data to require statue
        data={...data, status:"submitted", youtubeID:changeToYoutubeID(data.youtubeLink), category:changeCategotyToIndex(data.category)}
        console.log("final submit data", data)
        //update the formik
        props.formik.setFieldValue('youtubeID', changeToYoutubeID(data.youtubeLink))
        props.formik.setFieldValue('status', "submitted")
        props.formik.setFieldValue('category',changeCategotyToIndex(data.category))
        //console.log("handlePost before try", data)
                
        try{
            await axios.post(`${context.baseURL}video/`, data, { headers: context.token })
            //console.log("update new video")
            setVideoManageFeedback({Success: `Successfully update Video !`})
            setFeedbackOccur(true)
            props.formik.setValues(emptyState)
            context.setFirstSubmitVedio(false)
            context.setNumSubmit(prev => prev + 1)
            data={emptyState}
        } catch (error) {
            console.log(error)
            setVideoManageFeedback(Object.entries(error.response.data))
            //console.log(videoManageFeedback)
            setFeedbackOccur(true)
        }
        context.setReload(prev => prev + 1)
    }

    //change youtube link to youtube ID   
    const changeToYoutubeID = (data) =>{
        let newYoutubeID = ''
        //console.log("change to ID", data)
        // if (String(data) !== "Youtube Vedio ID"){
        if (data.includes('v=')){
            newYoutubeID = (data.split('v=')[1].split('&')[0]) // This will ensure if there are other parameters after 'v=', they won't be included.
        } else if (data.includes('youtu.be/')) {
            newYoutubeID = (data.split("?")[0].split("youtu.be/")[1])
        } else {
            // This assumes input youtubeID is directly the youtubeID, might need validation.
            newYoutubeID = (data) 
        }
        // }
        return newYoutubeID
    }

    //for delete button
    const handleDelete = async(data) => {
        try {
            //change the youtube link to youtube ID
            await axios.delete(`${context.baseURL}video/${data.youtubeID}/`, { headers: context.token });
            setVideoManageFeedback({Success: `Successfully delete Video !`})
            //console.log(videoManageFeedback)
            setFeedbackOccur(true)
        } catch (error) {
            setVideoManageFeedback(Object.entries(error.response.data))
            //console.log(videoManageFeedback)
            setFeedbackOccur(true)
        }
        context.setReload(prev => prev + 1)
    };

    //for update button
    const handleUpdate = async(data) => {
        const newYoutubeID = changeToYoutubeID(data.youtubeLink)

        if(data.youtubeID === newYoutubeID){
            //update the data array
            data={...data, category:changeCategotyToIndex(data.category)}
            //update the formik
            props.formik.setFieldValue('category',changeCategotyToIndex(data.category))
            //send the array to backend
            try {        
                await axios.put(`${context.baseURL}video/${data.youtubeID}/`, data,  { headers: context.token });
                setVideoManageFeedback({Success: `Successfully update Video !`})
                //console.log(videoManageFeedback)
                setFeedbackOccur(true)

              } catch (error) {
                setVideoManageFeedback(Object.entries(error.response.data))
                //console.log(videoManageFeedback)
                setFeedbackOccur(true)
              }
              context.setReload(prev => prev + 1)

        } else {
            //delet the old data
            try {
                //change the youtube link to youtube ID
                await axios.delete(`${context.baseURL}video/${data.youtubeID}/`, { headers: context.token });
                setVideoManageFeedback({Success: `Successfully delete Video !`})
                //console.log(videoManageFeedback)
                setFeedbackOccur(true)
            } catch (error) {
                setVideoManageFeedback(Object.entries(error.response.data))
                //console.log(videoManageFeedback)
                setFeedbackOccur(true)
            }
            //update the data array
            data={...data, youtubeID:newYoutubeID, category:changeCategotyToIndex(data.category)}
            //update the formik
            props.formik.setFieldValue('youtubeID', changeToYoutubeID(data.youtubeLink))
            props.formik.setFieldValue('category',changeCategotyToIndex(data.category))
            //send data to array
            //console.log("after delet", data)
            try{
                await axios.post(`${context.baseURL}video/`, data, { headers: context.token })
                //console.log("update new video")
                setVideoManageFeedback({Success: `Successfully update Video !`})
                setFeedbackOccur(true)
                props.formik.setValues(emptyState)
            } catch (error) {
                console.log(error)
                setVideoManageFeedback(Object.entries(error.response.data))
                //console.log(videoManageFeedback)
                setFeedbackOccur(true)
            }
        }
        context.setReload(prev => prev + 1)
        // //change data to require statue
        // data={...data, youtubeID:changeToYoutubeID(data.youtubeLink), category:changeCategotyToIndex(data.category)}
        // console.log("hand updata, origional data", data)
        // try {        
        //    await axios.put(`${context.baseURL}video/${data.youtubeID}/`, data,  { headers: context.token });
        //    setVideoManageFeedback({Success: `Successfully update Video !`})
        //    console.log(videoManageFeedback)
        //    setFeedbackOccur(true)
        //  } catch (error) {
        //    setVideoManageFeedback(Object.entries(error.response.data))
        //    console.log(videoManageFeedback)
        //    setFeedbackOccur(true)
        //  }
        //  context.setReload(prev => prev + 1)
      }

    //for changing states of the video
    const handleStatus = async(data, newStatus) => {       
        data={...data, status: newStatus, category:changeCategotyToIndex(data.category)}
        //update the formik
        props.formik.setFieldValue('status', newStatus)
        //console.log("handle status", props.formik.values)
        try { 
            //console.log("handle status, before url", data)
            await axios.put(`${context.baseURL}video/${data.youtubeID}/`, data,  { headers: context.token });
            //await axios.put(`${context.baseURL}video/${data.youtubeID}/`, props.formik.values,  { headers: context.token });
            //console.log("handlestatus, after URL", data)
            setVideoManageFeedback({Success: `Successfully update Video ${data.youtubeID} Status!`})
            //console.log(videoManageFeedback)
            setFeedbackOccur(true)
          } catch (error) {
            setVideoManageFeedback(Object.entries(error.response.data))
            //console.log(videoManageFeedback)
            console.log(error)
            //setFeedbackOccur(true)
          }
    }

    //change the categoty into index, because the backend only regonise index
    const changeCategotyToIndex = (data)=>{
        let categoryIndex = ""
        if (data === "Drama / Scripted"){
            categoryIndex = 1
        } else if (data === "Avant-Grade"){
            categoryIndex = 2
        } else if (data === "Documentary"){
            categoryIndex = 3
        } else if (data === "Animated"){
            categoryIndex = 4
        } else if (data === "Live-Action / Narrative"){
            categoryIndex = 5
        }

        return(categoryIndex)
    }

    //for getting data for each change
    return (
        <>
            <Container>
                <Row>                   
                    {/* video that is uploaded */}
                    {props.showVideo?
                        <Col>
                            <Card>
                                <Card.Body>
                                    <VideoComponent link = {props.formik.values.youtubeID}/>
                                </Card.Body>
                            </Card>
                        </Col>
                    :
                        null
                    }

                    {/* Video Form */}
                    <Col className='p-0 m-0'>
                        {/* Row 1 */}
                        <Row className='p-0 m-0'>
                            <Col className='p-0 m-0'>
                                {/* Youtube Link */}
                                <TableInputTemplate 
                                    formPurpose={props.formPurpose}
                                    type='text'
                                    label="Youtube Video Link (Required)"
                                    placeholder="Please enter your youtube Video Link"
                                    name={props.nameYoutubeLink}
                                    formik={props.formik}
                                    disabled={props.disableYoutubeLink}
                                />
                            </Col>

                            <Col sm={4} md={4} lg={4} xl={4} xxl={4} className='p-0 m-0'>
                                {/* Film Category */}
                                <TableInputTemplate 
                                    formPurpose={props.formPurpose}
                                    type="selection"
                                    label="Film Category (Required)"
                                    name={props.nameCategoryID}
                                    formik={props.formik}
                                    disabled={props.disabledCategory}
                                    selectionList={filmCategoty}
                                /> 
                            </Col>
                        </Row>

                        {/* Row 2 */}
                        <Row className='p-0 m-0'>
                            <Col className='p-0 m-0'>
                                {/* Publish Date */}
                                <TableInputTemplate 
                                    formPurpose={props.formPurpose}
                                    type="date"
                                    label="Publish Date (Required) (on or after 30 June 2019)"
                                    name={props.namePublishDate}
                                    formik={props.formik}
                                    disabled={props.disabledPublishDate}
                                />
                            </Col>
                            <Col className='p-0 m-0'>
                                {/* Status */}
                                <TableInputTemplate 
                                    formPurpose={props.formPurpose}
                                    type='text'
                                    label="Submition Status (by admin)"
                                    placeholder="Draft"
                                    name={props.nameStatus}
                                    formik={props.formik}
                                    disabled={props.disabledStatus}
                                />
                            </Col>
                        </Row>
                    
                        {/* Row 3 */}
                        {/* Video Title */}
                        <TableInputTemplate 
                            formPurpose={props.formPurpose}
                            type="text"
                            label="Film Title (Required)"
                            placeholder="Please enter Film Title"
                            name={props.nameVideoTitle}
                            formik={props.formik}
                            disabled={props.disabledVideoTitle}
                        />   

                        {/* Row 4 */}
                        {/* Video Description */}
                        <TableInputTemplate 
                            type="textarea"
                            rows={5}
                            controlID="videoDes"
                            label="Film Description (Required)"
                            name={props.nameVideoDescription}
                            placeholder="Please enter your film description, within 500 character"
                            disabled={props.disabledVideoDescription}
                            formik={props.formik}
                            formPurpose={props.formPurpose}
                        />  

                        {/* Row 5 */}
                        <Row className='p-0 m-0'>
                            <Col className='p-0 m-0'>
                                {/* Film Owner */}
                                <TableInputTemplate 
                                    formPurpose={props.formPurpose}
                                    type="text"
                                    label="Company (Required)"
                                    placeholder="Please enter Film Owner"
                                    name={props.nameFilmOwner}
                                    formik={props.formik}
                                    disabled={props.disableFilmOwner}
                                />    
                            </Col>
                            <Col className='p-0 m-0'>
                                {/* instagram */}
                                <TableInputTemplate 
                                    formPurpose={props.formPurpose}
                                    type="date"
                                    label="Date of Birth of Director (required)" 
                                    name={props.nameInstagram}
                                    formik={props.formik}
                                    disabled={props.disableInstagram}
                                />    
                            </Col>
                        </Row>

                        {/* Row 6 */}
                        <Row className='p-0 m-0'>
                            <Col className='p-0 m-0'>
                                {/* name of director */}
                                <TableInputTemplate 
                                    formPurpose={props.formPurpose}
                                    type="text"
                                    label="Director (optional)"
                                    placeholder="Please enter director name"
                                    name={props.nameDirector}
                                    formik={props.formik}
                                    disabled={props.disableDirector}
                                />    
                            </Col>
                            <Col className='p-0 m-0'>
                                {/* name of producer */}
                                <TableInputTemplate 
                                    formPurpose={props.formPurpose}
                                    type="text"
                                    label="Producer (optional)"
                                    placeholder="Please enter Producer name"
                                    name={props.nameProducer}
                                    formik={props.formik}
                                    disabled={props.disableProducer}
                                />   
                            </Col>
                        </Row>

                        {/* Row 7 */}
                        <Row className='p-0 m-0'>
                            <Col className='p-0 m-0'>
                                {/*editor */}
                                <TableInputTemplate 
                                    formPurpose={props.formPurpose}
                                    type="text"
                                    label="Editor (optional)"
                                    placeholder="Please enter Editor name"
                                    name={props.nameEditor}
                                    formik={props.formik}
                                    disabled={props.disableEditor}
                                />  
                            </Col>

                            <Col className='p-0 m-0'>
                                {/* Director of picture */}
                                <TableInputTemplate 
                                    formPurpose={props.formPurpose}
                                    type="text"
                                    label="Director of photography (optional)"
                                    placeholder="Please enter Director of picture"
                                    name={props.nameDOP}
                                    formik={props.formik}
                                    disabled={props.disableDOP}
                                />  
                            </Col>
                        </Row>

                        {/* Row 8 */}
                        {/* arthor email*/}
                        <TableInputTemplate 
                            formPurpose={props.formPurpose}
                            type="text"
                            label="Registered Email"
                            value={context.userData.email}
                            name={props.nameArthorEmail}
                            formik={props.formik}
                            disabled={props.disableArthorEmail}
                        />  


                        {/* Row 9 */}
                        <Row className='p-0 m-0'>
                            <Col sm={3} md={3} lg={3} xl={3} xxl={3} className='p-0 m-0'>
                                {/* area code */}
                                <TableInputTemplate 
                                    formPurpose={props.formPurpose}
                                    type="number"
                                    label="Area Code"
                                    placeholder="44"
                                    name={props.nameAreaCode}
                                    formik={props.formik}
                                    disabled={props.disableAreaCode}
                                />      
                            </Col>

                            <Col className='p-0 m-0'>
                                {/* telephone */}
                                <TableInputTemplate 
                                    formPurpose={props.formPurpose}
                                    type="number"
                                    label="Whatsapp account"
                                    placeholder="We will whatsapp you if you are nominated"
                                    name={props.nameTelephone}
                                    formik={props.formik}
                                    disabled={props.disableTelephone}
                                />    
                            </Col>
                        </Row>

                        <br/>

                        {/* Button for action */}
                        <Row className="flex justify-center">
                            {/* Submit button */}
                            {props.activeSubmitVideoButton? 
                                <Col>
                                    <Button variant="primary" disabled={disableButton} onClick={() => handlePost(props.formik.values)}>
                                        Submit New Vedio
                                    </Button>
                                </Col>
                            :""}

                            {/* Delete Button */}
                            {props.activeDeleteButton?
                                <Col>
                                    <Button variant="danger" disabled={disableButton} onClick={() => handleDelete(props.formik.values)}>
                                        Delete
                                    </Button>
                                </Col>
                            :""}

                            {/* Update Button */}
                            {props.activeUpdateButton?
                                <Col>
                                    <Button variant="primary" disabled={disableButton} onClick={() => handleUpdate(props.formik.values)}>
                                        Update
                                    </Button>
                                </Col>
                            :""}

                            {/* back to submit button */}
                            {context.userData.isStaff?
                                <Col>
                                    <Button variant="info" onClick={() => handleStatus(props.formik.values, "submitted")}>
                                        Back to submitted
                                    </Button>
                                </Col>
                            :""}

                            {/* semi final button */}
                            {context.userData.isStaff?
                                <Col>
                                    <Button variant="success" onClick={() => handleStatus(props.formik.values, "semi-final")}>
                                        to Semi Final
                                    </Button>
                                </Col>
                            :""}

                            {/*final button*/}
                            {context.userData.isStaff?
                                <Col>
                                    <Button variant="warning" onClick={() => handleStatus(props.formik.values, "final")}>
                                        to Final
                                    </Button>
                                </Col>
                            :""}
                        </Row>
                        <br/>
                    </Col>
                    
                </Row>
            </Container>


        </>
    )
}

export default VideoManageTable
