import React, {useState, useEffect, useContext} from 'react'
import Col from 'react-bootstrap/Col';
import { Context } from '../../../../../component/hooks/Wrapper';
import "./ShortlistFilterTemplate.css"

const ShortlistFilterTemplate = (props) => {

    //const style = `hover:bg-yellow-500 cursor-pointer flex justify-content-center align-item-center`
    //const style_no_hover = `cursor-pointer flex justify-content-center align-item-center`

    const [shortFilmListStyle, setShortFilmListStyle] = useState("");
    const context = useContext(Context) 

    const filterHandler = (e) => {
        context.setShortFilmFilter(props.content)
      }

    useEffect(()=>{
        if (context.shortFilmFilter === props.content) {
            //setShortFilmListStyle(style_no_hover + ' bg-amber-300')
            setShortFilmListStyle("shortFilmNavbarSelected shortFilmNavbarhover")
        }else{
            //setShortFilmListStyle(style)
            setShortFilmListStyle("shortFilmNavbarhover")
        }
        console.log(shortFilmListStyle)
    },[context.shortFilmFilter])  

    return (
        <>
        <Col className={shortFilmListStyle} onClick={filterHandler} id={props.content}>
            {props.content}
        </Col>
        </>
    )
}

export default ShortlistFilterTemplate
