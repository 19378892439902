import React, { useEffect, useRef, useState } from 'react';
import Ratio from 'react-bootstrap/Ratio';
import YouTube from 'react-youtube';
import LoadingBackdrop from './LoadingBackdrop';

const VideoComponent = (props) => {
  const videoRef = useRef(null);
  const [loading, setLoading] = useState(true);

  const handleMouseEnter = () => {
    if (!loading && videoRef.current) {
      videoRef.current.internalPlayer.playVideo();
      videoRef.current.internalPlayer.mute();
    }
  };

  const handleMouseLeave = () => {
    if (!loading && videoRef.current) {
      videoRef.current.internalPlayer.pauseVideo();
      videoRef.current.internalPlayer.unMute();
    }
  };

  const handleReady = (event) => {
    // because youtube always call index in different name, so i have to locate index by the following line
    const targetIndex =Object.keys(event.target)[7]
    //after locate the index, the onready key is in secondary boundy index
    const onreadyIndex = Object.keys(event.target[targetIndex])[0]
    //video is ready or not
    const ready = event.target[targetIndex][onreadyIndex]
    
    // const eventSting = CircularJSON.stringify(event.target);
    if (ready) {
      setLoading(false);
    }
  };

  const videoOptions = {
    width: '100%',
    height: '100%',
    playerVars: {
      cc_lang_pref: 'en',
      cc_load_policy: 1,
    },
  };

  return (
    <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      
      <Ratio aspectRatio="4x3">
        <YouTube
          className="videoPlayer"
          videoId={props.link}
          opts={videoOptions}
          onReady={handleReady}
          ref={videoRef}
        />
      </Ratio>
      {loading ?<LoadingBackdrop load = {loading}/>: null}
    </div>
  );
};

export default VideoComponent;