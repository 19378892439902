import React from 'react'
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import { useNavigate } from 'react-router-dom';

const ShortFilmCardTemplate = (props) => {
  const navigate = useNavigate()
  return (
    <>
        <Col md lg={props.rowSize}>
            <Card className="flex justify-content-center align-items-center">
            <Card.Img variant="top" src={props.img} style={{ height: props.picHeight + 'px'}}/>
            <Card.Body>
                <Button variant="warning" className='whitespace-nowrap' onClick={() => navigate(props.href)} disabled={props.disable}>
                  {props.buttonText}
                </Button>
            </Card.Body>
            </Card>
            <br/>
        </Col>
        
    </>
  )
}

export default ShortFilmCardTemplate
