import React, {useEffect} from 'react'
import ShortListFilmNavbar from './component/Navbar/ShortListFilmNavbar'
import Video from './component/Video/Video'

const ShortListFilms = () => {

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <>
      <ShortListFilmNavbar/>
      <Video/>
    </>
  )
}

export default ShortListFilms
