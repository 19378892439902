import React, {useContext, useState, useRef, useEffect} from 'react';
import { Container, Nav, Navbar, NavDropdown} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import { Context } from '../hooks/Wrapper';
import { FormattedMessage } from 'react-intl';
import './NavigationBar.css'
import English from "../../lang/en-GB.json"
import Chinese from "../../lang/zh-HK.json"
import Logo from '../logo/Logo';
import useWIndowSize from '../../component/hooks/useWIndowSize'
import axios from 'axios';
import ShareIcon from '@mui/icons-material/Share'
import {FacebookShareButton, TwitterShareButton, WhatsappShareButton, LinkedinShareButton} from "react-share"
import currentURL from '../../asset/database/currentURL.json'
import webDescription from '../../asset/database/webDesription.json'
import webTitle from '../../asset/database/webTitle.json'
//import logo from '../../asset/pic/LHKL_logo_S.jpg'
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

function NavigationBar() {

  const context = useContext(Context)
  const {screenDim} = useWIndowSize()

  //for check box, ture for english, false for chinese
  const [isChecked, setIsChecked] = useState(String(context.locale).includes("en")? true:false)

  const navbarRef = useRef(null)

  //function for changing language
  const checkHandler = () => {
    setIsChecked(!isChecked)
    context.setMessages(isChecked===true? Chinese : English)
    context.setLocale(isChecked===true? "zh" : "en")
  }
  
  
  //check the heigh of navbar
  useEffect(() => {
    // Function to calculate the navbar height
    const calculateNavbarHeight = () => {
      const height = navbarRef.current.offsetHeight;
      context.setNavbarHeight(height);
      context.setNoNavbarHeight(screenDim.height - height)
    };

    // Call the function initially
    calculateNavbarHeight();

    // Call the function again whenever the window is resized
    window.addEventListener('resize', calculateNavbarHeight);

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener('resize', calculateNavbarHeight);
    };
  }, []);

  //******************* 8 Sep for navbar close eachtime i click navlink*/
  const [navbarExpanded, setNavbarExpanded] = useState(false);

  const handleNavbarToggle = () => {
    setNavbarExpanded(!navbarExpanded);
  };

  const handleLinkClick = () => {
    setNavbarExpanded(false);
  };
  //******************* */

  //to handle log out 
  const handleLogout = async () => {
    //console.log(context.token)
    try {
      // Make an API call to log the user out on the Django backend
      await axios.post('/api/logout/', { token: context.token });
      
      // Once the logout is successful, you can redirect the user to the login page or perform any other actions you need.
      // Example redirect:
      //window.location.href = '/Login';
    } catch (error) {
      // Handle any error that may occur during the logout process
      console.error('Logout failed', error);
    }
  };

  return (
    <>
      {/* <Navbar className="navbar p-0" variant="dark" expand="lg" ref={navbarRef}> */}
      <Navbar className="navbar p-0" variant="dark" expand="lg" ref={navbarRef} expanded={navbarExpanded} onToggle={handleNavbarToggle}> 
        <Container fluid className="p-0">
          <Navbar.Brand as={Link} to="/" className="pt-0 pb-0 pl-5 m-0">
            <Logo/>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll" className="justify-content-end">
            <Nav>
              {/* Home button */}
              <Nav.Link as={Link} to="/ShortFilmFestival" onClick={handleLinkClick}>{<FormattedMessage id="app.shotrFileFestival" defaultMessage="Short Film Festival"/>}</Nav.Link>

              {/* Activities button */}
              <Nav.Link as={Link} to="/JobEvents" onClick={handleLinkClick}>{<FormattedMessage id="app.activities" defaultMessage="Activities"/>}</Nav.Link>

              {/* About us button */}
              <Nav.Link as={Link} to="/AboutUs" onClick={handleLinkClick}>{<FormattedMessage id="app.aboutUs" defaultMessage="About Us"/>}</Nav.Link>

              {/*userinfo*/}
              {context.loginStatus? 
              <Nav.Link as={Link} to="/UserInfo" onClick={handleLogout}>{<FormattedMessage id="app.userinfo" defaultMessage="User Info"/>}</Nav.Link>
              :
              null}


              {/* login and logout button */}
              {context.loginStatus? 
              <Nav.Link as={Link} to="/Logout" onClick={handleLogout}>{<FormattedMessage id="app.logout" defaultMessage="Log Out"/>}</Nav.Link>
              :
              <Nav.Link as={Link} to="/Login">{<FormattedMessage id="app.login" defaultMessage="Log In"/>}</Nav.Link>}

              {/* /*dropdown for share icons*/}
              <NavDropdown title={<ShareIcon className="text-lg"/>}>
                {/* for facebook share icon */}
                <NavDropdown.Item className="p-0 dropItem">
                  <FacebookShareButton
                    url={currentURL}
                    qoute={webDescription}
                    hashtag='#Short Film Festival'>
                    <FacebookIcon className="p-0" fontSize='large'/>
                  </FacebookShareButton> Facebook
                </NavDropdown.Item>

                {/* for twitter share icon */}
                <NavDropdown.Item className="p-0 dropItem">
                  <TwitterShareButton
                    url={currentURL}
                    qoute={webDescription}
                    hashtag='#Short Film Festival'>
                    <TwitterIcon className="p-0" fontSize='large'/>
                  </TwitterShareButton> Twitter
                </NavDropdown.Item>
                
                {/* for whatapps share */}
                <NavDropdown.Item className="p-0 dropItem">
                  <WhatsappShareButton
                    url={currentURL}
                    title={webTitle}>
                    <WhatsAppIcon className="p-0" fontSize='large'/>
                  </WhatsappShareButton> Whatsapp
                </NavDropdown.Item>

                {/* for twitter share icon */}
                <NavDropdown.Item className="p-0 dropItem">
                  <LinkedinShareButton
                    url={currentURL}
                    title={webTitle}
                    summary={webDescription}
                    source={webTitle}>
                    <LinkedInIcon className="p-0" fontSize='large'/>
                  </LinkedinShareButton> Linkedin
                </NavDropdown.Item>                

              </NavDropdown>
            </Nav>

            {/* language toggle switch */}
            <div className="d-flex align-items-center justify-content-center pe-2">
              <BootstrapSwitchButton
                checked={isChecked}
                onlabel="中"
                onstyle="dark outline-light"
                offlabel="Eng"
                offstyle="dark outline-light"
                onChange={checkHandler}
              />
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default NavigationBar;