// import React, {useContext, useEffect, useState} from 'react'
// import { Context } from '../../component/hooks/Wrapper';
// import { Col, Button, Row, Container, Card, Form } from "react-bootstrap";
// import forgetPasswordPic from '../../asset/pic/ForgetPassword.jpeg'
// import useWIndowSize from '../../component/hooks/useWIndowSize';

// const RegisterUser = () => {

//     const context = useContext(Context)
//     const [showImage, setShowImage] = useState(true)
//     const {screenDim} = useWIndowSize()

//     useEffect(()=>{
//         if (screenDim.width >= 992){
//           setShowImage(true)
//         } else if (screenDim.width < 992){
//           setShowImage(false)
//         }}
//         ,[screenDim.width]
//       )

//     return (
//         <>
//             <Container fluid className='m-0 p-0'>
//                 <Row>
//                     {showImage&&<Col md lg={6} className="flex justify-content-center align-items-center">
//                         <img src={forgetPasswordPic} alt='forget password' style={{height: context.noNavbarHeight + "px", backgroundSize: 'cover', backgroundPosition: 'center' }}/>
//                     </Col>}

//                     <Col md={12} lg={6} xs={12} className="p-0">
//                     <div className="d-flex justify-content-center align-items-center" style={{ height: context.noNavbarHeight + "px" }}>
//                         <Card style={{ width: '100%'}}>
//                             <Card.Body>
//                                 <h2 className="text-center">
//                                     Forget Password
//                                 </h2>
//                                 <br/>
//                                 <Form>
//                                     <Form.Group className="mb-3" controlId="formBasicEmail">
//                                         <Form.Label>Please enter your Email address</Form.Label>
//                                         <div className="border-1 rounded-lg border-gray-300">
//                                             <Form.Control type="email" placeholder="Enter email" />
//                                         </div>
//                                     </Form.Group>

//                                     <Container className="flex justify-content-center align-items-center">
//                                         <Button variant="warning" size="lg" type="submit">
//                                             Submit
//                                         </Button>
//                                     </Container>
//                                 </Form>
//                             </Card.Body>
//                         </Card>
//                         </div>
//                     </Col>
//                 </Row>
//             </Container>
//         </>
//     )
// }

// export default RegisterUser

//************************************ */
import React, { useContext, useEffect, useState } from 'react';
import { Context } from '../../component/hooks/Wrapper';
import { Col, Button, Row, Container, Card, Form } from "react-bootstrap";
import forgetPasswordPic from '../../asset/pic/ForgetPassword.jpeg';
import useWindowSize from '../../component/hooks/useWIndowSize';
import axios from 'axios';

const RegisterUser = () => {

    const context = useContext(Context);
    const [showImage, setShowImage] = useState(true);
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const { screenDim } = useWindowSize();

    useEffect(() => {
        if (screenDim.width >= 992) {
            setShowImage(true);
        } else if (screenDim.width < 992) {
            setShowImage(false);
        }
    }, [screenDim.width]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await axios.post(`${context.baseURL}auth/users/reset_password/`, { email });
            setMessage('Please check your email, your email may send to junk mail box.');
        } catch (error) {
            setMessage('Error sending email, please try again later.');
        }
    };

    return (
        <>
            <Container fluid className='m-0 p-0'>
                <Row>
                    {showImage && <Col md lg={6} className="flex justify-content-center align-items-center">
                        <img src={forgetPasswordPic} alt='forget password' style={{ height: context.noNavbarHeight + "px", backgroundSize: 'cover', backgroundPosition: 'center' }} />
                    </Col>}

                    <Col md={12} lg={6} xs={12} className="p-0">
                        <div className="d-flex justify-content-center align-items-center" style={{ height: context.noNavbarHeight + "px" }}>
                            <Card style={{ width: '100%' }}>
                                <Card.Body>
                                    <h2 className="text-center">
                                        Forget Password
                                    </h2>
                                    <br />
                                    <Form onSubmit={handleSubmit}>
                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <Form.Label>Please enter your Email address</Form.Label>
                                            <div className="border-1 rounded-lg border-gray-300">
                                                <Form.Control type="email" placeholder="Enter email" value={email} onChange={(e) => setEmail(e.target.value)} />
                                            </div>
                                        </Form.Group>

                                        <Container className="flex justify-content-center align-items-center">
                                            <Button variant="warning" size="lg" type="submit">
                                                Submit
                                            </Button>
                                        </Container>
                                        {message && <div className="mt-3 text-center">{message}</div>}
                                    </Form>
                                </Card.Body>
                            </Card>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default RegisterUser;

