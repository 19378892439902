import React, { useState, useContext } from 'react';
import { Button, Form } from 'react-bootstrap';
import axios from 'axios';
import { Context } from '../../component/hooks/Wrapper';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { useParams, useNavigate } from 'react-router-dom';

function ResetPasswordTable({ match }) {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { uid, token } = useParams();
  const context = useContext(Context)
  const navigate = useNavigate
  
//**************************** */
  const resetPassword = async () => {
    if (password !== confirmPassword) {
      setMessage('Passwords do not match');
      return;
    }

    try {

      console.log("uid", uid)
      console.log("token", token)
      const response = await axios.post(`${context.baseURL}auth/users/reset_password_confirm/`, {
        uid,
        token,
        new_password: password,
      });

      if (response.status === 200) {
        setMessage('Password reset successful');
        setPassword('');  // Clear the inputs
        setConfirmPassword('');  // Clear the inputs
        
        // Navigate after a short delay to allow the user to see the success message
        setTimeout(() => {
          navigate("/Login");
        }, 2000);
      }
    } catch (error) {
      console.error('Error resetting password:', error);
      
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        setMessage(`Error resetting password: ${error.response.data.detail}`);
      } else if (error.request) {
        // The request was made but no response was received
        setMessage('Error resetting password: No response from the server');
      } else {
        // Something happened in setting up the request that triggered an Error
        setMessage('Error resetting password: ' + error.message);
      }
    }
  };


//**************************** */


  // const resetPassword = async () => {
  //   if (password !== confirmPassword) {
  //     setMessage('Passwords do not match');
  //     return;
  //   }

  //   try {
  //     const response = await axios.post(`${context.baseURL}auth/users/reset_password_confirm/`, {
  //       "uid":uid,
  //       "token":token,
  //       "new_password":password,
  //     });

  //     if (response.status === 200) {
  //       setMessage('Password reset successful');
  //       setPassword('');  // Clear the inputs
  //       setConfirmPassword('');  // Clear the inputs
  //     }

  //     navigate("/Login")
  //   } catch (error) {
  //     setMessage('Error resetting password');
  //     console.log(error)
  //   }
  // };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', backgroundColor: '#f8f9fa' }}>
      <Container>
        <Row className="d-flex justify-content-center">
          <Row>
            <h1>Reset Password</h1>
          </Row>
        
          <Row>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                resetPassword();
              }}
            >
              <Form.Group controlId="formPassword">
                <Form.Label>New Password</Form.Label>
                <div className="d-flex">
                  <Form.Control 
                    type={showPassword ? "text" : "password"} 
                    placeholder="Enter new password" 
                    value={password}
                    onChange={(e) => setPassword(e.target.value)} 
                  />
                  <Button variant="outline-secondary" onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? 'Hide' : 'Show'}
                  </Button>
                </div>
              </Form.Group>

              <Form.Group controlId="formConfirmPassword">
                <Form.Label>Confirm Password</Form.Label>
                <div className="d-flex">
                  <Form.Control 
                    type={showConfirmPassword ? "text" : "password"} 
                    placeholder="Confirm new password" 
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)} 
                  />
                  <Button variant="outline-secondary" onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                    {showConfirmPassword ? 'Hide' : 'Show'}
                  </Button>
                </div>
              </Form.Group>

              <Button variant="primary" type="submit" block>
                Reset Password
              </Button>
            </Form>
            {message && <p style={{ textAlign: 'center', marginTop: '10px' }}>{message}</p>}
          </Row>
        </Row>
      </Container>
    </div>
  );
}

export default ResetPasswordTable;
