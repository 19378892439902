import React, {useContext, useRef, useEffect, useState} from 'react';
import './ShortFilm.css';
import Button from 'react-bootstrap/Button';
import videoBg from '../../asset/pic/ShortFilmCoverPageVideo.mp4';
import { FormattedMessage } from 'react-intl';
import UploadIcon from '@mui/icons-material/Upload';
import InfoIcon from '@mui/icons-material/Info';
import { Context } from '../../component/hooks/Wrapper';
import rulePic from '../../asset/pic/rule_and_regulation.jpeg'
import submitPic from '../../asset/pic/submitVideo.jpeg'
import joinPic from '../../asset/pic/whoCanJoin.jpeg'
import pamphletPic from '../../asset/pic/Pamphlet.jpg'
import ShortFilmCardTemplate from './template/ShortFilmCardTemplate';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import useWIndowSize from '../../component/hooks/useWIndowSize';
import { useNavigate } from 'react-router-dom';
import PDFView from '../../component/hooks/PDFView';
import PamphletPDF from "../../asset/pdf/ShortFilmFestivalPamphlet.pdf"

const ShortFilm = () => {

  const context = useContext(Context)
  const {screenDim} = useWIndowSize()
  const aboutFileFestivalRef = useRef(null)
  const navigate = useNavigate()

  const clickHandler = () => {
    aboutFileFestivalRef.current.scrollIntoView({ behavior: 'smooth' });
  }

  const picHeight = screenDim.height/2

  const [lang, setLang] = useState("")

  useEffect(()=>{
    String(context.locale).includes("en")? setLang("EN"):setLang("ZH")  
  },[context.locale])

  const buttonStyle = "border-0 p-2 m-3"

  return (
    <>
      <section className='coverpage'>
        <div className='coverpage' style={{ height: screenDim.height + "px" }}>
          <div className='overlay' style={{ height: screenDim.height + "px" }}></div>
          <video src={videoBg} width="100%" style={{ height: screenDim.height + "px" }} autoPlay loop muted/>
          <div className='content' style={{ height: "1000px" }}>
            <h1 className='pb-5'>
              <FormattedMessage id="app.shotrFileFestival" defaultMessage="Short File Festival" />
            </h1>
            <div className='grid grid-cols-2 gap-12  d-flex justify-content-center align-items-center'>
              <Button variant="light outline-dark" size="lg" className={buttonStyle} onClick={()=>navigate(context.loginStatus === true? "/UserInfo":"/Login")}>
                <UploadIcon fontSize='large' className='pr-2'/>
                <FormattedMessage id="app.submitYourVideo" defaultMessage="Submit your File" />
              </Button>
              
              <Button variant="light outline-dark" size="lg" className={buttonStyle} onClick={clickHandler}>
                <InfoIcon fontSize='large' className='pr-2'/>
                <FormattedMessage id="app.aboutShortFilmFestival" defaultMessage="About File Festival" />
              </Button>
            </div>
          </div>
        </div>
      </section> 

      <br/>

      <section ref={aboutFileFestivalRef}>
        <Container Fluid>
          <Row>
            <ShortFilmCardTemplate 
              rowSize={3} 
              picHeight={picHeight} 
              img={rulePic} 
              disable={false}
              href={'/RulesAndRegulations'}
              buttonText={<FormattedMessage id="app.rulesAndRegulations" defaultMessage="Rules and Regulations" />}
            />

            <ShortFilmCardTemplate 
              rowSize={3} 
              picHeight={picHeight} 
              img={submitPic} 
              disable={false}
              href={'/HowToSubmit'}
              buttonText={<FormattedMessage id="app.howToSubmitVedio" defaultMessage="How to submit your video" />}
            />
            
            <ShortFilmCardTemplate 
              rowSize={3} 
              picHeight={picHeight} 
              img={pamphletPic} 
              disable={false}
              href={'/DirectorBio'}
              buttonText={<FormattedMessage id="app.judgesBackground" defaultMessage="Judges Background" />}
            />
            
            {/* <ShortFilmCardTemplate 
              rowSize={3} 
              picHeight={picHeight} 
              img={joinPic}
              disable={false} 
              href={'/ShortListFilm'}
              buttonText={<FormattedMessage id="app.shortlistedFiled" defaultMessage="Shortlisted File" />}
            /> */}

            <ShortFilmCardTemplate 
              rowSize={3} 
              picHeight={picHeight} 
              img={joinPic}
              disable={false} 
              href={'/MovieDes'}
              buttonText={<FormattedMessage id="app.MovieDes" defaultMessage="Movie Description" />}
            />

          </Row>
        </Container>
      </section>

      <br/>
      <PDFView 
        PdfFile={PamphletPDF}
        fileWidth={screenDim.width*0.9}
      />
    </>
  );
};

export default ShortFilm;
