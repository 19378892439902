import React, {useState, useEffect} from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Heading from './template/Heading'
import Point from './template/Point'
import useWIndowSize from '../../component/hooks/useWIndowSize'

const FilmRulesEN = () => {

  const [show, setShow] = useState(true)
  const {screenDim} = useWIndowSize()

  useEffect(()=>{
    if (screenDim.width >= 580){
      setShow(true)
    } else if (screenDim.width < 580){
      setShow(false)
    }}
    ,[screenDim.width]
  )

  return (
    <>
      <Container Fluid>
        <Row>
          <h2 className='text-left underline'>Regulations for Liverpool Hong Kong Link CIC Short Film Festival:</h2>
        </Row>
        <br/>
        <Heading content={'Film Length:'}/>
        <Point show={show} content={'Submitted short films must not exceed 20 minutes in length.'}/>
        <br/>
        <Heading content={'Theme:'}/>
        <Point show={show} content={'The theme of the short films should revolve around or highlight the message of "Multicultural Communication."'}/>
        <Point show={show} content={'Films can explore topics such as cultural diversity, intercultural understanding, generation gap, culture shock, immigrants or any other aspect related to multicultural communication.'}/>
        <br/>
        <Heading content={'Judging Panel:'}/>
        <Point show={show} content={'A panel of professionals and individuals from relevant fields will sit as the judging panel for semi-finalists and finalists.'}/>
        <Point show={show} content={'The judging panel will evaluate the submitted films based on criteria such as skills, direction, storytelling, cinematography, acting, creativity, and relevance to the theme.'}/>
        <Point show={show} content={'Culturists and individual film makers as advisors for first round short listing. Panel of Judges with 4-5 professionals will select the best film from the finalists.'}/>
        <br/>
        <Heading content={'Film Screening:'}/>
        <Point show={show} content={'Short-listed semi-finalists will be displayed on an official Playlist through Youtube links.'}/>
        <Point show={show} content={'The films of the finalists will be screened in a cinema theatre at the city centre of Liverpool in April 2024. Admission is free through online registration with a first come first served basis.'}/>
        <br/>
        <Heading content={'Submission Dates:'}/>
        <Point show={show} content={'The submission period for will begin on 15 September, 2023 to 30 Nov 2023  GMT 23:55'}/>
        <br/>
        <Heading content={'Submission Method:'}/>
        <Point show={show} content={'Participants shall register and fill the online application form on Liverpool Hong Kong Link CIC (http://www.lhkl.org.uk).'}/>
        <Point show={show} content={'Filmmakers should upload their short films on their Youtube account for official publishing on a specific playlist.'}/>
        <Point show={show} content={'MP4 format ratio of 16:9 is recommended Finalists need to provide a technical format (DCP format) for screening conversion.'}/>
        <Point show={show} content={'Recommended Format: HD 1080p (1920×1080) ProRes 422 HQ MOV video file.'}/>
        <br/>
        <Heading content={'Entry Rules and Deadlines:'}/>
        <Point show={show} content={'Liverpool Hong Kong Link CIC reserves the right to modify the entry rules and deadlines if necessary.'}/>
        <Point show={show} content={'Shortlisted semi-finalists shall agree the hyperlinking of their submitted films to the official Youtube channel playlist for public viewing.'}/>
        <Point show={show} content={'Finalists should provide a 30 sec trailer for official promotion and relevant copyright as archive for the organizer.'}/>
        <Point show={show} content={'Finalists need to upload or send an extra digital copy for theatre screening in accordance with the technical requirements of the cinema.'}/>
        <Point show={show} content={'Submitted short films must be produced after 2019 (i.e. produced no more than 3 and a half years)'}/>
        <Point show={show} content={'English subtitles required for non-English languages films'}/>
        <Point show={show} content={'Maximum 3 productions per unit for submission.'}/>
        <Point show={show} content={'Participants should check the official website and Facebook page for latest updates or changes in due course.'}/>
        <br/>
        <Heading content={'Copyright'}/>
        <Point show={show} content={'Copyright of all submitted productions belong to the film makers and the organiser of the festival will not keep the copies after online or theatre screening except the 30 second teasers/trailers of the finalists for promotion purposes and official archive.'}/>
        <br/>
        <Point show={false} content={'Please note that the above regulations are a general outline based on the information provided. It is recommended to refer to the official website and Facebook page for the most accurate and up-to-date submission guidelines and details.'}/>
      </Container>
    </>
  )
}

export default FilmRulesEN
