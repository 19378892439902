import React, {useContext, useState, useEffect} from 'react'
import { Context } from '../../component/hooks/Wrapper';
import logoutBg from "../../asset/pic/logout.jpeg"
import Button from 'react-bootstrap/Button'
import { useNavigate } from "react-router-dom";

const Logout = () => {

    const context = useContext(Context)
	const navigate = useNavigate();
    const [redirectToIndex, setRedirectToIndex] = useState(false);

    const handleRedirect = () => {
        setRedirectToIndex(true);
        navigate('/');
        context.setLoginStatus(false)
      };

    
    // Set up a timeout to redirect to index after 5 seconds
    useEffect(() => {
        const timer = setTimeout(() => {
        if (!redirectToIndex) {
            navigate('/'); // Redirect to index page after 5 seconds if the button wasn't clicked
            setRedirectToIndex(false)
            context.setLoginStatus(false)
        }
        }, 2500);

        // Clear the timeout on component unmount to prevent memory leaks
        return () => clearTimeout(timer);
    }, [redirectToIndex, navigate]);

    return (
        <>
            <div className='overlay' style={{ height: context.noNavbarHeight + "px" }}></div>
            <img src={logoutBg} alt="logoutBg" style={{height: context.noNavbarHeight + "px", backgroundSize: 'cover', backgroundPosition: 'center' }}/>
            <div className='content'>
                <h1 className='pb-5'>
                    You are log out
                </h1>

                <Button variant="warning" onClick={handleRedirect}>Back to Index</Button>
            </div> 
        </>
    )
}

export default Logout
