import React, {useContext, useEffect, useState} from 'react'
import { Context } from '../../../../component/hooks/Wrapper';
import VideoComponent from './template/VideoComponent';
import Card from 'react-bootstrap/Card'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import axios from 'axios';

const Video = () => {

  const [VedioData, setVedioData] = useState(null)
  const [firstLoading, setFirstLoading] = useState(0)
  const context = useContext(Context)

  //for geting video data from backend
  const handleGetVideo = async() => {
    axios.get(`${context.baseURL}video/ShortList/final`)
    .then(response => {
      setVedioData(response.data);
    }).catch(error => {
      console.log(error)
    })
  }
    //for getting data in the begining
    useEffect(() => {
      if (VedioData===null){
        if (firstLoading<=10){ 
          handleGetVideo()
          setFirstLoading(prev=>prev+1)
          //console.log("get data by net", firstLoading, VedioData)
        }} 
      else {
          //console.log("get data no net", firstLoading, VedioData)
        }
      
    }, [firstLoading]);

  return (
    <>
      {VedioData===null?
        <div>
          Loading...
        </div>
        :
        <Container>
          <Row>
            {VedioData.filter((data)=>{
              if(context.shortFilmFilter === data.category) {
                return data
              }else if (context.shortFilmFilter === 'All'){
                return data
              }
            }).map((data, key) => {
              return (
                <Col md={6} lg={4}>
                  {/* <Card key={key} style={{ width: '25rem' }} className="mb-8"> */}
                  <Card key={key} className="mb-8">
                    <Card.Body>
                      <Card>
                        <VideoComponent 
                          link = {data.youtubeID}
                        />
                      </Card>
                      <Card.Title>
                        <div className = "text-2xl">
                          {data.videoTitle}
                        </div>
                      </Card.Title>
                    </Card.Body>
                  </Card>
                </Col>)
            })}
          </Row>
        </Container>
      }
     
    </>
  )
}

export default Video


