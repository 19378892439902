import React from 'react'
import './AboutUsDB.css'

const ProjectEN = () => {
  return (
    <>
        <h2>Project Introduction</h2>
        <h3>
          1. Employment Project
        </h3>
        <p className='AboutUsContent'>
          In collaboration with Merseyside Community Training, we will organize an employment mentality and planning workshop, skills training, and entrepreneurship workshop, etc to assist the Hong Kongers in Merseyside to enhance their skills, understand job requirements, and prepare for employment.
        </p>
        <h3>
          2. Short-film Project
        </h3>
        <p className='AboutUsContent'>
          We will organise the first Liverpool Multicultural Short Film Festival in early 2024. The event aims at promoting multicultural communication through films and facilitating short film development in regional and global perspectives. Independent film makers, amateur film makers and student film makers will be invited to submit their works from mid-September 2023. Experienced judges and film making professionals will be our panel of judges. The best works are entitled to awards as well as cash rewards. On the other hand, 20 selected best short films will be screening online globally and in theatre at Liverpool by spring 2024.
        </p>
    </>
  )
}

export default ProjectEN
