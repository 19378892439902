import React, { useContext, useEffect, useState } from 'react';
import { Context } from '../../component/hooks/Wrapper';
import { Col, Button, Row, Container, Card, Form, Table } from "react-bootstrap";
import registerPic from '../../asset/pic/register.jpeg';
import useWindowSize from '../../component/hooks/useWIndowSize';
import validationSchema from '../../FormValidation/validationSchema'
import { useFormik } from 'formik';
import { FormattedMessage } from 'react-intl';
import './RegisterUser.css'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
// import * as Yup from 'yup';

const RegisterUser = () => {
    const context = useContext(Context);
    const [showImage, setShowImage] = useState(true);
    const { screenDim } = useWindowSize();
    const [errorArray, setErrorArray] = useState({})
    const [errorOccur, setErrorOccur] = useState(false)
    const navigate = useNavigate()

    const formik = useFormik({
        initialValues: {
            username: 'username',
            FirstName: '',
            LastName: '',
            email: '',
            password: '',
            confirmPassword: ''
        },
        validationSchema: validationSchema,
        // onSubmit: handleOnSubmit
    });

    // Moved the onSubmit handler function above useFormik declaration
    const handleOnSubmit = async(e) => {  
        e.preventDefault();
        if (
            String(formik.errors.FirstName) === "undefined" &&
            String(formik.errors.LastName) === "undefined" &&
            String(formik.errors.email) === "undefined" &&
            String(formik.errors.password) === "undefined" &&
            String(formik.errors.confirmPassword) === "undefined" 
        ) {           
                
            try {
                // Make sure response is defined
                
                await axios.post(`${context.baseURL}user/register/`, {
                    username: formik.values.email,
                    first_name: formik.values.FirstName,
                    last_name: formik.values.LastName,
                    email: formik.values.email,
                    password: formik.values.password
                });
                //console.log("handleOnSubmit after post", formik.values)
                setErrorOccur(false)
                context.setLoginStatus(true)
                navigate('/Login')
                // You might want to do something with the response here.
    
            } catch(error) {
                //console.error("There was an error with the request:", error);
                //console.log(error.response.data)
                //console.log("1", Object.keys(error.response.data))
                //console.log("2", Object.keys(error.response.data).length)
                //console.log("3", (error.response.data)[Object.keys(error.response.data)[1]])
                //console.log("4", Object.values(error.response.data))
                // Handle the error appropriately.
                //setErrorArray((error.response.data))
                setErrorArray(Object.entries(error.response.data))
                //setErrorArray(Object.values(error.response.data))
                setErrorOccur(true)
                // console.log(error.response.data)
                // console.log(errorArray)
            }

        }
    };

    useEffect(() => {
        if (screenDim.width >= 992) {
            setShowImage(true);
        } else if (screenDim.width < 992) {
            setShowImage(false);
        }
    }, [screenDim.width]);

    return (
        <>
            <Container fluid className='m-0 p-0'>
                <Row>
                    {showImage && <Col md lg={6} className="flex justify-content-center align-items-center">
                        <img src={registerPic} alt='register' style={{ height: context.noNavbarHeight + "px", backgroundSize: 'cover', backgroundPosition: 'center' }} />
                    </Col>}

                    <Col md={12} lg={6} xs={12}>
                        <Card>
                            <Card.Body>
                                <h2 className="text-center">
                                    <FormattedMessage id="app.registerForm" defaultMessage="Register Form" />
                                    
                                </h2>

                                <Form onSubmit={formik.onSubmit}>
                                    {/* Email field */}
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>
                                            <FormattedMessage id="app.email" defaultMessage="Email address" />
                                        </Form.Label>
                                        <div className="border-1 rounded-lg border-gray-300">
                                            <Form.Control 
                                                className={formik.errors.email? 'RegisterFormError':''}
                                                type="email" 
                                                name="email" 
                                                onChange={formik.handleChange} 
                                                onBlur={formik.handleBlur} 
                                                value={formik.values.email} 
                                                placeholder="Enter email" 
                                            />
                                        </div>
                                        {formik.touched.email && formik.errors.email ? <div className="text-danger">{formik.errors.email}</div> : null}
                                        <Form.Text className="text-muted">We'll never share your email with anyone else.</Form.Text>
                                    </Form.Group>

                                    {/* First name and Last name */}
                                    <Container Fluid className="m-0 p-0">
                                        <Row>
                                            <Col>
                                                <Form.Group>
                                                    <Form.Label>
                                                        <FormattedMessage id="app.firstName" defaultMessage="First Name" />
                                                    </Form.Label>
                                                    <div className="border-1 rounded-lg border-gray-300">
                                                        <Form.Control 
                                                            className={formik.errors.FirstName ? 'RegisterFormError':''}
                                                            type="text" 
                                                            name="FirstName" 
                                                            onChange={formik.handleChange} 
                                                            onBlur={formik.handleBlur} 
                                                            value={formik.values.FirstName} 
                                                            placeholder="First Name" 
                                                        />
                                                    </div>
                                                    {formik.touched.FirstName && formik.errors.FirstName ? <div className="text-danger">{formik.errors.FirstName}</div> : null}
                                                </Form.Group>
                                            </Col>

                                            <Col>
                                                <Form.Group>
                                                    <Form.Label>
                                                        <FormattedMessage id="app.lastName" defaultMessage="Last Name" />
                                                    </Form.Label>
                                                    <div className="border-1 rounded-lg border-gray-300">
                                                        <Form.Control 
                                                            className={formik.errors.LastName ? 'RegisterFormError':''}
                                                            type="text" 
                                                            name="LastName" 
                                                            onChange={formik.handleChange} 
                                                            onBlur={formik.handleBlur} 
                                                            value={formik.values.LastName} 
                                                            placeholder="Last Name" 
                                                        />
                                                    </div>
                                                    {formik.touched.LastName && formik.errors.LastName ? <div className="text-danger">{formik.errors.LastName}</div> : null}
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Container>

                                    {/* Password field */}
                                    <Form.Group className="mb-3" controlId="formBasicPassword">
                                        <Form.Label>
                                            <FormattedMessage id="app.registerPassword" defaultMessage="Password" />
                                        </Form.Label>
                                        <div className="border-1 rounded-lg border-gray-300">
                                            <Form.Control 
                                                className={formik.errors.password ? 'RegisterFormError':''}
                                                type="password" 
                                                name="password" 
                                                onChange={formik.handleChange} 
                                                onBlur={formik.handleBlur} 
                                                value={formik.values.password} 
                                                placeholder="Password" 
                                            />
                                        </div>
                                        {formik.touched.password && formik.errors.password ? <div className="text-danger">{formik.errors.password}</div> : null}
                                    </Form.Group>

                                    {/* Confirm Password field */}
                                    <Form.Group className="mb-3" controlId="formBasicPassword">
                                        <Form.Label>
                                            <FormattedMessage id="app.retypePassword" defaultMessage="Retype your Password" />
                                        </Form.Label>
                                        <div className="border-1 rounded-lg border-gray-300">
                                            <Form.Control 
                                                className={formik.errors.confirmPassword ? 'RegisterFormError':''}
                                                type="password" 
                                                name="confirmPassword" 
                                                onChange={formik.handleChange} 
                                                onBlur={formik.handleBlur} 
                                                value={formik.values.confirmPassword} 
                                                placeholder="Password" 
                                            />
                                        </div>
                                        {formik.touched.confirmPassword && formik.errors.confirmPassword ? <div className="text-danger">{formik.errors.confirmPassword}</div> : null}
                                    </Form.Group>

                                    <br />

                                    {errorOccur?
                                    <Table striped bordered hover>
                                        <tbody>
                                            {errorArray.map(([key, value]) => (
                                                <tr key={key}>
                                                    <td className="text-danger">{key}</td> 
                                                    <td className="text-danger">{value}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                    :
                                    null}

                                    <Container className="flex justify-content-center align-items-center">
                                        
                                        <Button variant="warning" size="lg" type="submit" onClick={handleOnSubmit}>
                                            <FormattedMessage id="app.submit" defaultMessage="Submit" />
                                        </Button>
                                    </Container>


                                </Form>
                            </Card.Body>
                        </Card>
                        <br />
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default RegisterUser;
