import React, {useContext, useState, useEffect} from 'react'
import { Context } from '../../../../component/hooks/Wrapper'


const Event1description = () => {

    const context = useContext(Context)

    const [lang, setLang] = useState("")
  
    useEffect(()=>{
      String(context.locale).includes("en")? setLang("EN"):setLang("ZH")  
    },[context.locale])

    return (
        <div>
            {lang === "ZH"?
            <p>為移英香港人設計的一站式講座：生涯規劃及就業，退休社會保障，持續進修，創業需知及初創培訓等。</p>
            :
            <p>One stop workshop for career planning, continuous training, entrepreneurship etc </p>
            }

            {lang === "ZH"?
            <p>講者包括：Dept of Work and Pension DWP官員；Dr Fiona Armstrong-Gibbs (Liverpool John Moore University 商學院講師；Mrs Sarah Walker (MD, Cisco); Mr Kevin Roger (CEO, MCT)等等。</p>
            :
            <p>The speakers include: Department of Work and Pension (DWP) officials; Dr. Fiona Armstrong-Gibbs (Lecturer at Liverpool John Moores University Business School); Mrs. Sarah Walker (Managing Director, Cisco); Mr. Kevin Roger (CEO, MCT), and so on.</p>
            }

            {lang === "ZH"?
            <p>內容針對香港人就業，進修，創業及退休安排，現埸提供中英傳譯。並有西北區RSMP官員介紹BNO新移民輔導服務。查詢：info@lhkl.org.uk</p>
            :
            <p>The content focuses on employment, further education, entrepreneurship, and retirement arrangements for Hong Kong people. On-site translation between Chinese and English is provided. Northwest District RSMP officials will also introduce BNO new immigrant counseling services. For inquiries, please contact info@lhkl.org.uk.</p>
            }

        </div>
    )
}

export default Event1description
