import React from 'react'
import Alert from 'react-bootstrap/Alert';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import './AlertBar.css'

const AlertBar = () => {

  const navigate = useNavigate()

  return (
    <Alert variant="warning" className="p-0 m-0">
        <Alert.Heading className="m-0">
        <div className="flex flex-row lg:h-4 xs:h-7">
            <p className="pr-1 hover:underline hover:cursor-pointer alertText m-0" onClick={(e)=>{
                        e.preventDefault();
                        window.open("https://www.kickstarter.com/projects/liverpoolshortfilm/liverpool-multicultural-short-film-festival-2024?ref=project_build", "_blank")
                      }}>
              <strong className="hover:underline hover:cursor-pointer">
                  <FormattedMessage id="app.caution"  defaultMessage="Caution"/>
              </strong>
              <FormattedMessage id="app.cautionEvent"  defaultMessage="LHKL Inauguration Event cum Career Workshop and launch of Liverpool Short Film Festival 2024"/>
            </p>
        </div> 
        </Alert.Heading>
    </Alert>
  )
}

export default AlertBar