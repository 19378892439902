import React, {useContext, useState, useEffect} from 'react'
import { Context } from '../../../../component/hooks/Wrapper'
import Table from 'react-bootstrap/Table';
import "./Event2description.css"

const Events2Table2 = () => {

    const context = useContext(Context)

    const [lang, setLang] = useState("")

    useEffect(()=>{
      String(context.locale).includes("en")? setLang("EN"):setLang("ZH")  
    },[context.locale])

    const WorkPensionWeb = "https://www.gov.uk/government/organisations/department-for-work-pensions"
    const MCTwebsite = "https://mymct.co.uk/"
    const NWRSPMWeb = "https://northwestrsmp.org.uk/"

    return (
        <>
            <Table striped bordered hover variant="light">
                <thead>
                    <tr>
                        <th>{lang==="EN"? "Time" : "時間" }</th>
                        <th>{lang==="EN"? "Item" : "項目"}</th>
                        <th>{lang==="EN"? "Speaker" : "講者"}</th>
                    </tr>
                </thead>
                <tbody>
                    {/* row 1 */}
                    <tr >
                        <td>09:30am-10:00am</td>
                        <td>{lang==="EN"?"Registration":"登記"}</td>
                        <td>/</td>
                    </tr>

                    {/* row 2 */}
                    <tr>
                        <td>10:00am-10:10am</td>
                        <td>{lang==="EN"?"Opening":"開幕"}</td>
                        <td>HK</td>
                    </tr>   

                    {/* row 3 */}
                    <tr>
                        <td>10:10am-10:20am</td>
                        <td>{lang==="EN"?"Opening Remark":"開幕歡迎辭"}</td>
                        <td>
                            {lang==="EN"?
                            <div>
                                <p>Mr. Joseph Sheehan (Project Manager)</p>
                                <p>Megan Sampson (Project Officer)</p>
                                <p><a href={NWRSPMWeb}>North West Regional Strategic Migration Partnership (RSMP) Manchester City Council</a></p>
                            </div>
                            :
                            <div>
                                <p>Mr. Joseph Sheehan (項目經理)</p>
                                <p>Megan Sampson (項目主任)</p>
                                <p><a href={NWRSPMWeb}>英格蘭西北部香港英國國民(海外)歡迎中心和區域策略移民合作伙伴關係(RSMP)曼徹斯特市議會</a></p>
                            </div>}
                        </td>
                    </tr>

                    {/* row 4 */}
                    <tr>
                        <td>10:20am-10:25am</td>
                        <td>{lang==="EN"?"Thank you guest":"感謝嘉賓"}</td>
                        <td>HK</td>
                    </tr> 

                    {/* row 5 */}
                    <tr>
                        <td>10:25am-10:55am</td>
                        <td>
                            {lang==="EN"?
                            <div>
                                <p>Session1: Employability and Retirement</p>
                                <p>(Employment and Retirement arrangements for HongKongers)</p>
                            </div>
                            :
                            <div>
                                <p>第一節：就業能力及退休</p>
                                <p>(針對香港人就業及退休安排)</p>
                            </div>}
                        </td>
                        <td>
                            {lang==="EN"?
                            <div>
                                <p>Kevin Rodgers (CEO <a href={MCTwebsite}>MyMCT</a>)</p>
                                <p>Representative of <a href={WorkPensionWeb}>Department for Work&Pensions</a></p>
                            </div>
                            :
                            <div>
                                <p>Kevin Rodgers (默西塞德郡社區培訓首席執行官)</p>
                                <p><a href={WorkPensionWeb}>就業及養老金部門</a></p>
                            </div>}
                        </td>
                    </tr>

                    {/* row 6 */}
                    <tr>
                        <td>10:55am-11:35am</td>
                        <td>
                            {lang==="EN"?
                                <div>
                                    <p>Session2: Career Seminar</p>
                                    <p>(Recruitment in different industries)</p>
                                </div>
                                :
                                <div>
                                    <p>第二節：行業探索</p>
                                    <p>(行業招聘簡介)</p>
                                </div>
                            }
                        </td>
                        <td>
                            {lang==="EN"?
                            <div>
                                <p>Professionals:</p>
                                <p>Hospitality, Construction, Finance/Administration, Housing</p>
                            </div>
                            :
                            <div>
                                <p>專業人士:</p>
                                <p>酒店業、建築業、金融及行政業、房屋業等</p>
                            </div>}
                        </td>
                    </tr>

                    {/* row 7 */}
                    <tr>
                        <td>11:35am-11:55am</td>
                        <td>
                            {lang==="EN"?
                            <div>
                                <p>Session3: Enterprise</p>
                                <p>(Youth Entrepreneurship Training and Funding Opportunities)</p>
                            </div>
                            :
                            <div>
                                <p>第三節：創業</p>
                                <p>(青年創業培訓介紹及獲得資助機會)</p>
                            </div>}
                        </td>
                        <td>
                            {lang==="EN"?
                            <div>
                                <p>Cassandra Stewart</p>
                                <a href={MCTwebsite}><p>(Development Officer) – MyMCT</p></a>
                            </div>
                            :
                            <div>
                                <p>Cassandra Stewart</p>
                                <a href={MCTwebsite}><p>(默西塞德郡社區培訓發展主任)</p></a>
                            </div>}
                        </td>
                    </tr>                    

                    {/* row 8 */}
                    <tr>
                        <td>11:55am-12:20pm</td>
                        <td>
                            {lang==="EN"?
                            "Presentation of LHKL community services and Launch of Short Film Festival"
                            :
                            "利港連線提供的社區服務及利物浦短片電影節"}
                        </td>
                        <td>HK/Johnnie/Martens</td>
                    </tr>

                    {/* row 9 */}
                    <tr>
                        <td>12:20pm-12:30pm</td>
                        <td>
                            {lang==="EN"?
                            "Q&A"
                            :"問答"}
                        </td>
                        <td>HK</td>
                    </tr>

                    {/* row 10 */}
                    <tr>
                        <td>12:30pm</td>
                        <td>
                            {lang==="EN"?
                            "End of Event"
                            :
                            "活動完結"}
                        </td>
                        <td></td>
                    </tr>

                </tbody>
            </Table>
        </>
    )
}

export default Events2Table2
