import React from 'react'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
// import NavDropdown from 'react-bootstrap/NavDropdown';
import { FormattedMessage } from 'react-intl';
import { Button } from 'react-bootstrap';


const JobEventsNavbar = () => {

    const location = ['liverpool', 'St. Helen', 'Warrington']

    return (
        <>
            <Navbar expand="lg" className="bg-body-tertiary">
                <Container Fluid>
                    <Navbar.Collapse id="basic-navbar-nav"  className="justify-content-end">
                        <Nav className="me-auto">
                            {/* <NavDropdown title="Dropdown" id="basic-nav-dropdown">
                                {location.map((data) => (
                                    <NavDropdown.Item href="#action/3.1" key={data}>
                                        {data}
                                    </NavDropdown.Item>
                                ))}
                            </NavDropdown> */}

                            <Button variant="warning" href="/JobEventsCalender">
                                {<FormattedMessage id="app.calenderView" defaultMessage="Calender View"/>}
                            </Button>

                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    )
}

export default JobEventsNavbar
