import React from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './AboutUsTemplate.css'


const AboutUsTemplate = (props) => {
  return (
    <>
        <Row>
          {props.show && 
          <Col md lg={6} className="flex justify-content-center align-items-center">
              <img src={props.pic} alt={props.pic}/>
          </Col>}
          <Col className="flex justify-content-center align-items-center">
            <Row className='pr-6'>
              {props.content}
            </Row>
          </Col>
        </Row>
        <br/>
    </>
  )
}

export default AboutUsTemplate
