import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import ShortlistFilterTemplate from './template/ShortlistFilterTemplate';
import TypesOfVideo from '../../../../asset/database/VideoCategory.json'

const ShortListFilmNavbar = () => {
  return (
    <>
        <nav className="filmNavbar">
            {/* <h1 className="bg-amber-400 m-0 pl-4">Short listed Film</h1> */}
            <Container fluid className="bg-amber-400">
                <Row>
                  <ShortlistFilterTemplate content="All"/>
                    {TypesOfVideo.map((data,key)=>{
                      return(
                        <ShortlistFilterTemplate content={data}/>
                      )
                    })}
                </Row>
            </Container>
      </nav>
    </>
  )
}

export default ShortListFilmNavbar
